import { Component, Input, OnInit } from '@angular/core';
import { Font } from 'ngx-font-picker';

@Component({
    selector: 'app-article-portfolio',
    templateUrl: './article-portfolio.component.html',
    styleUrl: './article-portfolio.component.scss',
})
export class ArticlePortfolioComponent implements OnInit {
    @Input() articlePortfolio: any;
    @Input() typeTextColor: any;
    @Input() primaryBgColor: any;
    @Input() bodyFont: Font | any;

    articlePortfolioFont: Font | any;

    constructor() {}

    ngOnInit(): void {
        this.articlePortfolioFont = new Font(
            this.articlePortfolio.portfolioFonts,
        );
        this.bodyFont = new Font(this.bodyFont);

        if (this.articlePortfolio.override) {
            const portfolio = document.getElementById('portfolio');
            if (portfolio) {
                portfolio.style.backgroundColor =
                    this.articlePortfolio.portfolioBgColor;
            }
        } else {
            const portfolio = document.getElementById('portfolio');
            if (portfolio) {
                portfolio.style.backgroundColor = this.primaryBgColor;
            }
        }
    }
}
