<div class="cs-loader-container">
    <div class="csl-skeleton">
        <div class="skeleton-body">
            <div class="wrapper">
                <ngx-skeleton-loader
                    [theme]="{
                        width: '100%',
                        'border-radius': '1.3rem',
                        height: '400px',
                        'margin-bottom': '20px'
                    }"
                ></ngx-skeleton-loader>
            </div>
        </div>
    </div>
</div>
