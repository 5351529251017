import { Component, OnInit } from '@angular/core';
import { AppInitService } from './app.init.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    isLoading = true;
    constructor(private appInitService: AppInitService) {}

    ngOnInit(): void {
        this.appInitService.initApp().finally(() => {
            this.isLoading = false;
        });
    }
}
