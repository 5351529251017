import { Router } from '@angular/router';
import { AuthService } from './../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { LoginService } from './login.service';
import { MatDialog } from '@angular/material/dialog';
import { ForgotPasswordComponent } from '../components/forgot-password/forgot-password.component';

import {
    ACCESS_KEYS,
    LocalStorageService,
} from '../shared/services/local-storage.service';
/** Error when invalid control is dirty, touched, or submitted. */
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
    isloading: boolean = false;
    opacityLevel: any;
    messageAlert: any;
    newUserCheck: boolean = false;
    bpAlert: boolean = false;
    loginFormControl = new FormControl('', [
        Validators.required,
        Validators.email,
    ]);
    pressroomId: any;
    durationInSeconds = 5;
    constructor(
        public auth: AuthService,
        public route: Router,
        private loginService: LoginService,
        private dialog: MatDialog,
        public http: HttpClient,
        private localStorageService: LocalStorageService,
    ) {}

    login(userEmail: string, userPassword: string) {
        this.isloading = true;
        this.opacityLevel = 0.5;
        this.loginService.loginUser(userEmail, userPassword).subscribe(
            (val: any) => {
                if (this.auth.isAuthenticated()) {
                    if (this.auth.isPasswordUpdated()) {
                        this.loginService
                            .updateAccountList()
                            .then((value: any) => {
                                this.setCurrentPressroom(value);
                                this.route.navigate(['/dashboard']);
                            });
                    } else {
                        this.route.navigate(['/settings/user-account']);
                    }
                    this.isloading = false;
                    this.opacityLevel = 1;
                } else {
                    this.messageAlert = 'Username or password are not valid';
                    this.bpAlert = true;
                    this.opacityLevel = 1;
                    this.isloading = false;
                }
            },
            (err: any) => {
                if (err.error.response) {
                    this.messageAlert = err.error.response;
                } else {
                    this.messageAlert = 'Username or password are not valid';
                }
                this.bpAlert = true;
                this.opacityLevel = 1;
                this.isloading = false;
            },
        );
    }

    setCurrentPressroom(value: string | any[]) {
        if (
            this.localStorageService
                .getItem(ACCESS_KEYS.CURRENT_PRESSROOM)
                ?.toString() === 'noPressroom'
        ) {
            if (value.length > 0) {
                if (value[0].pressrooms.length > 0) {
                    this.pressroomId = value[0].pressrooms[0].id;
                    this.localStorageService.setItem(
                        ACCESS_KEYS.CURRENT_PRESSROOM,
                        value[0].pressrooms[0].id,
                    );
                } else {
                    this.localStorageService.setItem(
                        ACCESS_KEYS.CURRENT_PRESSROOM,
                        'noPressroom',
                    );
                }
            } else {
                this.localStorageService.setItem(
                    ACCESS_KEYS.CURRENT_PRESSROOM,
                    'noPressroom',
                );
            }
        }
    }
    forgotPassword() {
        const forgotPassword = this.dialog.open(ForgotPasswordComponent);
        forgotPassword.afterClosed().subscribe((result) => {});
    }
}
