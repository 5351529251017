<ul
    id="highlight-story-slider"
    *ngIf="storyArticles"
    class="mainstory"
    style="padding: 0; margin: 0"
>
    <li *ngFor="let storyArticle of storyArticles">
        <div style="height: 400px">
            <a class="panel" (click)="openArticle(storyArticle)">
                <div
                    class="panel__content main-story"
                    [ngStyle]="{
                        'background-color': config.cardArticlecolor
                    }"
                >
                    <div
                        *ngIf="
                            storyArticle.image_URL &&
                            !futureStories.hideStoryImage
                        "
                        class="panel__media"
                    >
                        <img
                            class="featureImg"
                            style="max-height: 400px"
                            [src]="storyArticle.image_URL"
                            loading="lazy"
                        />
                    </div>
                    <div class="panel__body">
                        <div
                            [ngClass]="
                                storyArticle.image_URL &&
                                !futureStories.hideStoryImage
                                    ? ''
                                    : 'panel__body_without_image'
                            "
                        >
                            <span
                                style="
                                    font-size: small;
                                    display: block;
                                    margin-bottom: 1em;
                                "
                                *ngIf="contentCategory?.showOnStory"
                            >
                                <app-category-chip
                                    [categoryId]="storyArticle.categoryId"
                                    [categories]="
                                        contentCategory.categories ?? []
                                    "
                                    [bgColor]="
                                        contentCategory?.categoryBackground
                                    "
                                    [textColor]="
                                        contentCategory?.categoryTextColor
                                    "
                                ></app-category-chip>
                            </span>
                            <span style="display: flex; flex-direction: row">
                                <time
                                    class="panel__date"
                                    [ngStyle]="{
                                        color: config.cardDatecolor
                                    }"
                                >
                                    {{
                                        storyArticle.date
                                            | date
                                                : config.articleDateFormat
                                                : ''
                                                : config.articleDateLanguage
                                    }}
                                </time>
                                &nbsp;
                            </span>
                            <span
                                style="display: flex; align-items: center"
                                *ngIf="!config.hideAuthor"
                                [ngStyle]="{
                                    color: config.cardAuthorColor
                                }"
                            >
                                <span
                                    class="separator"
                                    [ngStyle]="{
                                        'background-color': config.typeTextColor
                                    }"
                                ></span>
                                <h4
                                    style="
                                        margin: 0;
                                        padding: 0;
                                        font-size: 12px;
                                    "
                                >
                                    {{ storyArticle.author | slice: 0 : 25 }}
                                </h4>
                            </span>
                            <span
                                style="
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                "
                            >
                                <h3
                                    class="panel__heading line-limit"
                                    [ngStyle]="{
                                        color: config.cardTitlecolor
                                    }"
                                >
                                    {{ storyArticle.title | slice: 0 : 140 }}
                                    <span *ngIf="storyArticle.title > 140">
                                        ...
                                    </span>
                                </h3>
                            </span>

                            <p
                                style="word-break: keep-all"
                                class="line-limit"
                                [ngStyle]="{
                                    color: config.cardIngresscolor
                                }"
                            >
                                {{
                                    htmlToPlaintext(
                                        storyArticle?.ingress ||
                                            storyArticle.main_Body
                                    ) | slice: 0 : 300
                                }}
                                <span
                                    *ngIf="
                                        storyArticle?.ingress.lengh > 300 ||
                                        storyArticle?.main_Body.lengh > 300
                                    "
                                >
                                    ...
                                </span>
                            </p>

                            <button
                                mat-button
                                class="btn"
                                [ngStyle]="{
                                    'background-color': config.typeTextColor,
                                    color: config.viewMoreColor,
                                    width: '150px'
                                }"
                            >
                                {{ config.viewMore }}
                            </button>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </li>
</ul>

<div class="mainstory" *ngIf="!storyArticles">
    <app-story-skeleton></app-story-skeleton>
</div>
