<div class="site-body" id="site-body" [ngStyle]="font?.getStyles()">
    <div
        class="banner"
        id="banner"
        *ngIf="!hideBanner && !showSlider"
        @fadeInOut
    >
        <div class="overlay" id="bannerOverlay" *ngIf="bannerTextSlides"></div>
        <div class="slider-container" *ngIf="bannerTextSlides">
            <div class="carousel">
                <ul id="light-slider" style="padding: 0; margin: 0">
                    <li *ngFor="let slide of bannerTextSlides">
                        <div class="slide" [ngStyle]="{ height: bannerHeight }">
                            <h1
                                [ngStyle]="{
                                    'font-size': bannerHeaderTextSize
                                }"
                            >
                                {{ slide.mainText }}
                            </h1>
                            <h3
                                [ngStyle]="{
                                    'font-size': bannerSubHeaderTextSize
                                }"
                            >
                                {{ slide.subHeadline }}
                            </h3>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <!-- Skeleton Needed -->
    <div width="100%">
        <div
            *ngIf="showSlider === true"
            style="
                text-align: center;
                margin: 0;
                padding: 0;
                display: block;
                margin-left: auto;
                margin-right: auto;
            "
            [ngStyle]="{
                width: sliderWidth
            }"
            @fadeInOut
        >
            <ng-image-slider
                #nav
                style="all: initial"
                [images]="imageObject"
                [infinite]="sliderConfig.sliderInfinite"
                [autoSlide]="sliderConfig.sliderAutoSlide"
                [animationSpeed]="sliderConfig.sliderAnimationSpeed"
                [imagePopup]="sliderConfig.sliderImagePopup"
                [manageImageRatio]="sliderConfig.manageImageRatio"
                [imageSize]="{
                    width: sliderConfig.sliderImageWidth,
                    height: sliderConfig.sliderImageHeight
                }"
                [slideImage]="sliderConfig.sliderSlideImage"
                [videoAutoPlay]="sliderConfig.videoAutoPlay"
                [direction]="sliderConfig.direction"
                [showVideoControls]="sliderConfig.showVideoControls"
                [showArrow]="sliderConfig.HideShowSliderArrow"
            ></ng-image-slider>
        </div>
    </div>

    <div
        *ngIf="futureStories && isFutureStory"
        class="container mainstory"
        @fadeInOut
    >
        <div class="row">
            <div class="body-heading">
                <span
                    class="separator"
                    *ngIf="storySectionTitle && separatorStyle === 1"
                    [ngStyle]="{ 'background-color': typeTextColor }"
                ></span>
                <h1 *ngIf="storySectionTitle !== ''">
                    {{ storySectionTitle }}
                </h1>
                <span
                    class="separator-2"
                    *ngIf="storySectionTitle && separatorStyle === 2"
                    [ngStyle]="{ 'background-color': typeTextColor }"
                ></span>
            </div>
        </div>
        <app-future-story
            [futureStories]="futureStories"
            [config]="articleCardConfig"
            [contentCategory]="contentCategory"
        ></app-future-story>
    </div>

    <div
        class="container"
        *ngIf="!isPrintArticleLoading && printArticle.length > 0"
        @fadeInOut
    >
        <div class="row">
            <div class="body-heading">
                <span
                    class="separator"
                    *ngIf="contentTitle && separatorStyle === 1"
                    [ngStyle]="{ 'background-color': typeTextColor }"
                ></span>
                <h1 *ngIf="contentTitle !== ''">
                    {{ contentTitle }}
                </h1>
                <span
                    class="separator-2"
                    *ngIf="contentTitle && separatorStyle === 2"
                    [ngStyle]="{ 'background-color': typeTextColor }"
                ></span>
            </div>
            <div style="width: 100%">
                <div
                    *ngIf="(cardStyle === 1 && articleCardConfig) || !cardStyle"
                    class="content-container"
                >
                    <section class="card-1-section">
                        <app-article-card-one
                            *ngFor="
                                let item of printArticle
                                    | paginate
                                        : {
                                              itemsPerPage: itemsPerPage,
                                              currentPage: currentPage,
                                              totalItems
                                          }
                            "
                            [article]="item"
                            [config]="articleCardConfig"
                            [contentCategory]="contentCategory"
                        ></app-article-card-one>
                    </section>
                </div>
                <!-- Card -->
                <div
                    *ngIf="cardStyle === 2 || !cardStyle"
                    class="content-container"
                >
                    <div
                        *ngFor="
                            let item of printArticle
                                | paginate
                                    : {
                                          itemsPerPage: itemsPerPage,
                                          currentPage: currentPage,
                                          totalItems
                                      }
                        "
                    >
                        <app-article-card-two
                            [article]="item"
                            [config]="articleCardConfig"
                            ngClassStyle="width-50"
                            [contentCategory]="contentCategory"
                        ></app-article-card-two>
                    </div>
                </div>

                <!-- Card -->
                <div *ngIf="cardStyle === 3">
                    <div class="col-lg-12">
                        <div style="margin: auto 0">
                            <div
                                *ngFor="
                                    let item of printArticle
                                        | paginate
                                            : {
                                                  itemsPerPage: itemsPerPage,
                                                  currentPage: currentPage,
                                                  totalItems
                                              };
                                    index as i
                                "
                                class="card-three"
                                style="padding-bottom: 30px"
                            >
                                <app-article-card-three
                                    [article]="item"
                                    [config]="articleCardConfig"
                                    [contentCategory]="contentCategory"
                                ></app-article-card-three>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Card -->
                <div
                    *ngIf="cardStyle === 4 || !cardStyle"
                    class="content-container"
                >
                    <div class="col-lg-12">
                        <div
                            *ngFor="
                                let item of printArticle
                                    | paginate
                                        : {
                                              itemsPerPage: itemsPerPage,
                                              currentPage: currentPage,
                                              totalItems
                                          }
                            "
                        >
                            <app-article-card-two
                                [article]="item"
                                [config]="articleCardConfig"
                                ngClassStyle="width-50"
                                [noImage]="true"
                                [contentCategory]="contentCategory"
                            ></app-article-card-two>
                        </div>
                    </div>
                </div>

                <!-- Card -->
                <div
                    *ngIf="cardStyle === 5 || !cardStyle"
                    class="content-container"
                >
                    <div class="col-lg-12">
                        <div
                            *ngFor="
                                let item of printArticle
                                    | paginate
                                        : {
                                              itemsPerPage: itemsPerPage,
                                              currentPage: currentPage,
                                              totalItems
                                          }
                            "
                        >
                            <app-article-card-two
                                [article]="item"
                                [config]="articleCardConfig"
                                ngClassStyle="width-100"
                                [noImage]="true"
                                [contentCategory]="contentCategory"
                            ></app-article-card-two>
                        </div>
                    </div>
                </div>

                <!-- Card -->
                <div
                    *ngIf="cardStyle === 6 || !cardStyle"
                    class="content-container"
                >
                    <div class="col-lg-12">
                        <div
                            *ngFor="
                                let item of printArticle
                                    | paginate
                                        : {
                                              itemsPerPage: itemsPerPage,
                                              currentPage: currentPage,
                                              totalItems
                                          }
                            "
                        >
                            <app-article-card-two
                                [article]="item"
                                [config]="articleCardConfig"
                                ngClassStyle="width-100"
                                [noImage]="false"
                                hideSocialIcons="true"
                                isCompact="true"
                                [contentCategory]="contentCategory"
                            ></app-article-card-two>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Pagination -->
            <div
                class="view-more-button-container"
                [style]="
                    '--current-text-color: ' + paginationTextColor
                        | sanitizeHtml: 'style'
                "
                *ngIf="printArticle.length > 0 && !isPrintArticleLoading"
            >
                <pagination-controls
                    *ngIf="isPaginationOnHome && typeTextColor"
                    class="pagination"
                    [style]="
                        '--current-bg-color : ' + typeTextColor
                            | sanitizeHtml: 'style'
                    "
                    [maxSize]="maxSize"
                    [directionLinks]="directionLinks"
                    [responsive]="responsive"
                    [previousLabel]="paginationPreviousLabel"
                    [nextLabel]="paginationNextLabel"
                    (pageChange)="onPageChange($event)"
                    [totalItems]="totalItems"
                    [itemsPerPage]="itemsPerPage"
                    [currentPage]="currentPage"
                ></pagination-controls>

                <button
                    *ngIf="!isPaginationOnHome"
                    mat-button
                    class="btn"
                    (click)="openNews()"
                    [ngStyle]="{
                        'background-color': typeTextColor,
                        color: showMoreColor
                    }"
                >
                    <span class="showmore-text-container">{{ showMore }}</span>
                </button>
            </div>
        </div>
    </div>

    <div class="container" *ngIf="isPrintArticleLoading" @fadeInOut>
        <app-article-cards-skeleton></app-article-cards-skeleton>
    </div>
    <div
        class="container"
        style="padding-bottom: 2em"
        *ngIf="articlePortfolio?.showOnHome"
    >
        <div class="body-heading" [ngStyle]="font?.getStyles()">
            <span
                class="separator"
                *ngIf="
                    articlePortfolio?.portfolioSectionTitle &&
                    separatorStyle === 1
                "
                [ngStyle]="{ 'background-color': typeTextColor }"
            ></span>
            <h1 *ngIf="articlePortfolio.portfolioSectionTitle !== ''">
                {{ articlePortfolio.portfolioSectionTitle }}
            </h1>
            <span
                class="separator-2"
                *ngIf="
                    articlePortfolio?.portfolioSectionTitle &&
                    separatorStyle === 2
                "
                [ngStyle]="{ 'background-color': typeTextColor }"
            ></span>
        </div>
        <img
            *ngIf="analyticsUrl"
            [appLazyLoad]="analyticsUrl"
            loading="lazy"
            class="analytics-image"
        />
        <app-article-portfolio
            style="color: initial"
            [articlePortfolio]="articlePortfolio"
            [typeTextColor]="typeTextColor"
            [primaryBgColor]="primaryBgColor"
            [bodyFont]="font"
        ></app-article-portfolio>
    </div>
</div>
