<div class="card4">
    <div
        class="wrapper4 {{ config.cardImageStyle }}"
        [ngStyle]="{
            'background-image': article.image_URL
                ? 'url(' + article.image_URL + ')'
                : 'url(' + config.defaultCardImage + ')',
            'background-repeat':
                config.cardImageStyle === 'cover' ? 'no-repeat' : 'repeat'
        }"
    >
        <div [ngStyle]="config.backgroundStyleDynamic">
            <div
                class="wrapper4 {{ config.cardImageStyle }}"
                [ngStyle]="{
                    'background-image': article.image_URL
                        ? 'url(' + article.image_URL + ')'
                        : 'url(' + config.defaultCardImage + ')',
                    'background-repeat': 'no-repeat',

                    'background-size':
                        config.cardImageStyle === 'cover' ? 'cover' : 'contain'
                }"
            >
                <div
                    class="date"
                    [ngStyle]="{
                        'background-color': config.typeTextColor
                    }"
                >
                    <span
                        [ngStyle]="{
                            color: config.cardDatecolor
                        }"
                        class="day"
                    >
                        {{
                            article.date
                                | date: 'dd' : '' : config.articleDateLanguage
                        }}
                    </span>
                    <span
                        [ngStyle]="{
                            color: config.cardDatecolor
                        }"
                        class="month"
                    >
                        {{
                            article.date
                                | date: 'MMM' : '' : config.articleDateLanguage
                        }}
                    </span>
                    <span
                        [ngStyle]="{
                            color: config.cardDatecolor
                        }"
                        class="year"
                    >
                        {{
                            article.date
                                | date: 'yyyy' : '' : config.articleDateLanguage
                        }}
                    </span>
                </div>
                <div class="data">
                    <div
                        class="contentCard4"
                        [ngStyle]="{
                            background: config.cardArticlecolor
                        }"
                    >
                        <div
                            style="font-size: x-small"
                            class="category-container"
                            *ngIf="contentCategory?.showOnCards"
                        >
                            <app-category-chip
                                [categoryId]="article?.categoryId"
                                [categories]="contentCategory.categories ?? []"
                                [bgColor]="contentCategory?.categoryBackground"
                                [textColor]="contentCategory?.categoryTextColor"
                            ></app-category-chip>
                        </div>
                        <a
                            style="cursor: pointer"
                            class="text-content-link"
                            (click)="openArticle(article)"
                        >
                            <span
                                class="author"
                                [ngStyle]="{
                                    color: config.cardAuthorColor,
                                    'font-weight': 'bold'
                                }"
                                *ngIf="!config.hideAuthor"
                            >
                                {{ article.author | slice: 0 : 25 }}
                            </span>

                            <h3
                                [ngStyle]="{
                                    'font-size': '20px',
                                    'font-weight': 'bold',
                                    color: config.cardTitlecolor
                                }"
                                class="title line-limit-3"
                            >
                                {{ article.title | slice: 0 : 100 }}
                                <span *ngIf="article?.title?.length > 100">
                                    ...
                                </span>
                            </h3>
                            <p
                                [ngStyle]="{
                                    'font-size': '16px',
                                    color: config.cardIngresscolor
                                }"
                                class="text line-limit-4"
                            >
                                {{
                                    htmlToPlaintext(
                                        article?.ingress || article.main_Body
                                    ) | slice: 0 : 180
                                }}
                                <span
                                    *ngIf="
                                        article?.ingress?.length > 180 ||
                                        article?.main_Body?.length > 180
                                    "
                                >
                                    ...
                                </span>
                            </p>
                        </a>
                        <label
                            for="show-menu_{{ article._id }}"
                            class="menu-button"
                        >
                            <span></span>
                        </label>
                    </div>

                    <input type="checkbox" id="show-menu_{{ article._id }}" />
                    <ul class="menu-content">
                        <li
                            [ngStyle]="{
                                'background-color': config.typeTextColor
                            }"
                        >
                            <div class="social-icons">
                                <a
                                    style="text-decoration: none"
                                    target="_blank"
                                    href="{{ shareFB(article) }}"
                                    class="icon-facebook share-facebook"
                                ></a>
                            </div>
                        </li>
                        <li
                            [ngStyle]="{
                                'background-color': config.typeTextColor
                            }"
                        >
                            <div class="social-icons">
                                <a
                                    style="text-decoration: none"
                                    target="_blank"
                                    href="{{ shareTwitter(article) }}"
                                    class="icon-x share-twitter"
                                ></a>
                                <script
                                    async
                                    src="https://platform.x.com/widgets.js"
                                    charset="utf-8"
                                ></script>
                            </div>
                        </li>
                        <li
                            [ngStyle]="{
                                'background-color': config.typeTextColor
                            }"
                        >
                            <div class="social-icons">
                                <a
                                    style="text-decoration: none"
                                    target="_blank"
                                    href="{{ shareLinkedIn(article) }}"
                                    class="icon-linkedin share-linkedIn"
                                ></a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
