import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root',
})
export class SnackbarService {
    horizontalPosition: MatSnackBarHorizontalPosition = 'end';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    message$ = new Subject<string>();

    constructor(private _snackBar: MatSnackBar) {}

    /**
     * Shows a snackbar with custom delay
     * @param message The message to display
     * @param delay The amount of time (ms) to wait before dismissing the snackbar. Must be between 4000 and 10000. Default 8000.
     */
    showMessageWithDelay(message: string, delay: number = 8000) {
        this._snackBar.open(message, '', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: delay,
            panelClass: ['custom-snackbar'],
        });
    }
}
