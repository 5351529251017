import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import {
    ACCESS_KEYS,
    LocalStorageService,
} from './shared/services/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class AppService {
    private authToken: string = '';
    private isLoading!: boolean;
    private timezone!: string;
    authTokenUpdated$ = new ReplaySubject<void>(1);
    loadingStatusUpdated$ = new ReplaySubject<void>(1);

    constructor(private localStorageService: LocalStorageService) {
        this.isLoading = true;
        this.timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    get getAuthToken(): string {
        return (
            this.authToken ||
            this.localStorageService.getItem(ACCESS_KEYS.AUTH_TOKEN)
        );
    }

    get localTimezone() {
        return this.timezone;
    }

    get getLoadingStatus() {
        return this.isLoading;
    }

    setLoadingStatus(isLoading: boolean) {
        this.isLoading = isLoading;
        this.loadingStatusUpdated$.next();
    }

    setAuthToken(authToken: string) {
        this.authToken = authToken;
        this.authTokenUpdated$.next();
    }
}
