<div class="cs-loader-container">
    <div class="csl-skeleton">
        <div class="skeleton-body">
            <div class="wrapper">
                <ngx-skeleton-loader
                    [theme]="{
                        width: '20%',
                        'border-radius': '0',
                        height: '30px',
                        'margin-bottom': '20px'
                    }"
                ></ngx-skeleton-loader>
            </div>
            <div
                class="wrapper"
                style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                "
            >
                <ngx-skeleton-loader
                    style="width: 100%"
                    [theme]="{
                        width: '100%',
                        'border-radius': '1.3rem',
                        height: '600px',
                        'margin-bottom': '20px'
                    }"
                ></ngx-skeleton-loader>
                <ngx-skeleton-loader
                    style="width: 20%"
                    [theme]="{
                        width: '100%',
                        height: '30px',
                        'margin-bottom': '20px'
                    }"
                ></ngx-skeleton-loader>
            </div>
        </div>
    </div>
</div>
