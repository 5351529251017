// Service to fetch content for the news article
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../../src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class SubscribeService {
    viewFullArticle = new BehaviorSubject<any>(null);
    constructor(
        public http: HttpClient,
        public route: Router,
    ) {}

    submitSubscriber(reqBody: any): Observable<any> {
        const pressroomId = this.route.parseUrl(this.route.url).root.children
            .primary.segments[1].path;
        const url = `${environment.url}api/v1/subscribe/${pressroomId}`;

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        return this.http.post<any>(url, reqBody, { headers });
    }

    getSubscriber(pressroomId: any, userId: any): Observable<any> {
        const url = `${environment.url}api/v1/subscribe/${pressroomId}/user/${userId}`;
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        return this.http.get<any>(url, { headers });
    }
}
