<div
    class="logo"
    *ngIf="data.logo"
    [ngStyle]="{
        'background-color': data.logoBgColor ? data.logoBgColor : '#ffffff'
    }"
>
    <img [src]="data.logo" style="max-width: 50%" />
</div>
<div class="dialog-header">
    <!-- Close Button -->
    <button mat-icon-button class="close-button" (click)="onNoClick()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div *ngIf="isFetchingData">
    <div class="loaderMain" style="height: 250px">
        <div class="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div>
<div class="container" *ngIf="!isFetchingData">
    <div class="form-container">
        <!-- Error Message -->
        <div class="error-bar" *ngIf="isError">
            <mat-icon>error_outline</mat-icon>
            <span>{{ 'error' | translate }}</span>
        </div>

        <!-- Show form if the user is not subscribed -->
        <ng-container *ngIf="!isSubscribed && !alreadySubscriberUserId">
            <!-- Header with Title -->
            <h2>
                {{
                    buttonsConfigs?.followDisplayText ?? 'subscribe' | translate
                }}
                {{ 'title' | translate }}
            </h2>
            <p class="description">{{ 'description' | translate }}</p>

            <!-- Subscription Form -->
            <form
                [formGroup]="subscriberForm"
                style="display: flex; flex-direction: column"
                (ngSubmit)="onSubmit()"
            >
                <mat-form-field color="accent" appearance="outline">
                    <mat-label>{{ 'enter_email' | translate }}</mat-label>
                    <input
                        matInput
                        formControlName="email"
                        placeholder="{{ 'enter_email' | translate }}"
                        type="email"
                        required
                    />
                </mat-form-field>

                <!-- Checkbox Field for Terms of Use -->
                <mat-checkbox
                    formControlName="terms"
                    color="accent"
                    class="terms-checkbox"
                >
                    {{ 'agreement' | translate }}
                    <a
                        href="https://www.blumepress.com/terms.html"
                        target="_blank"
                    >
                        {{ 'terms' | translate }}
                    </a>
                    &
                    <a
                        href="https://www.blumepress.com/privacy.html"
                        target="_blank"
                    >
                        {{ 'privacy' | translate }}
                    </a>
                    .
                </mat-checkbox>

                <!-- Google reCAPTCHA -->
                <re-captcha
                    style="margin-top: 1em"
                    (resolved)="resolved($event)"
                    siteKey="6LfN2SoqAAAAAMzIkrzYa1rpg5PLZ-mrE-Xr79t_"
                ></re-captcha>

                <button
                    mat-button
                    type="submit"
                    [ngStyle]="{
                        color: buttonsConfigs?.followTextColor ?? '#000',
                        'background-color':
                            buttonsConfigs?.followBgColor ?? '#fff',
                        'border-radius': buttonsConfigs?.followButtonType
                            ? '2em'
                            : '0'
                    }"
                    [disabled]="
                        !subscriberForm.valid ||
                        !recaptchaToken ||
                        !subscriberForm.value.terms
                    "
                >
                    <span *ngIf="!isLoading">
                        {{
                            buttonsConfigs?.followDisplayText ?? 'subscribe'
                                | translate
                        }}
                    </span>
                    <span *ngIf="isLoading">
                        <mat-spinner
                            [strokeWidth]="5"
                            [diameter]="20"
                            [style]="
                                '--current-text-color : ' +
                                    buttonsConfigs.followTextColor
                                    | sanitizeHtml: 'style'
                            "
                        ></mat-spinner>
                    </span>
                </button>
            </form>
        </ng-container>

        <!-- Show success message if the user is already subscribed -->
        <ng-container *ngIf="isSubscribed && alreadySubscriberUserId">
            <div class="success-message" *ngIf="isOptedIn && !isUnsubscribed">
                <mat-icon class="success-icon">check_circle</mat-icon>
                <h2>{{ 'thankYouTitle' | translate }}</h2>
                <p
                    [innerHTML]="
                        'thankYouDescription'
                            | translate: { email: alreadySubscriberEmail }
                            | sanitizeHtml: 'html'
                    "
                ></p>
            </div>
            <div class="success-message" *ngIf="isUnsubscribed">
                <mat-icon class="success-icon" style="color: #de270b">
                    sentiment_dissatisfied
                </mat-icon>
                <h2 style="color: #de270b">
                    {{ 'unsubscribeTitle' | translate }}
                </h2>
                <p
                    [innerHTML]="
                        'unsubscribeDescription'
                            | translate: { email: alreadySubscriberEmail }
                            | sanitizeHtml: 'html'
                    "
                ></p>
                <button
                    mat-button
                    [ngStyle]="{
                        color: buttonsConfigs?.followTextColor ?? '#000',
                        'background-color':
                            buttonsConfigs?.followBgColor ?? '#fff',
                        'border-radius': buttonsConfigs?.followButtonType
                            ? '2em'
                            : '0'
                    }"
                    (click)="reSubscribe()"
                >
                    <span>
                        {{
                            buttonsConfigs?.followDisplayText ?? 'subscribe'
                                | translate
                        }}
                    </span>
                </button>
            </div>
            <div class="success-message" *ngIf="!isUnsubscribed && !isOptedIn">
                <mat-icon class="success-icon" style="color: #ea8e37">
                    mark_email_unread
                </mat-icon>
                <h2 style="color: #ea8e37">{{ 'pendingTitle' | translate }}</h2>
                <p
                    [innerHTML]="
                        'pendingDescription'
                            | translate: { email: alreadySubscriberEmail }
                            | sanitizeHtml: 'html'
                    "
                ></p>
            </div>
        </ng-container>
    </div>
</div>
