import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { NewsService } from '../news/news.service';
declare var $: any;

@Component({
    selector: 'app-future-story',
    templateUrl: './future-story.component.html',
    styleUrl: './future-story.component.scss',
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None,
})
export class FutureStoryComponent implements AfterViewInit {
    @Input() futureStories: any;
    @Input() config: any;
    @Input() contentCategory: any;

    pagination: any = {
        itemsPerPage: 7,
        page: 1,
        sortBy: 'date',
        sortOrder: -1,
        searchText: '',
        isVisible: true,
        isStory: true,
    };

    storyArticles: any;
    constructor(
        private router: Router,
        private newsService: NewsService,
    ) {}

    ngAfterViewInit() {
        if (this.futureStories.isFutureStory) {
            this.pagination.itemsPerPage = this.futureStories?.limit || 7;
            if (this.futureStories?.isNonPublished)
                this.pagination.isVisible = 0;

            this.newsService
                .getArticles(this.pagination)
                .subscribe((articleData: any) => {
                    if (articleData.response) {
                        this.storyArticles = articleData.response;
                        if (this.storyArticles)
                            setTimeout(() => {
                                $('#highlight-story-slider').lightSlider({
                                    controls: true,
                                    auto: true,
                                    loop:
                                        this.storyArticles &&
                                        this.storyArticles.length > 1
                                            ? true
                                            : false,
                                    item: 1,
                                    slideMove: 1,
                                    freeMove: false,
                                    pager: true,
                                    speed: 2000,
                                    pause: 4500,
                                    pauseOnHover: true,
                                });
                            });
                    }
                });
        }
    }

    htmlToPlaintext(text: any) {
        try {
            const doc = new DOMParser().parseFromString(text, 'text/html');
            text = doc.body.textContent || '';
        } catch (ex) {}
        text = text.replace(/\s\s+/g, ' ');
        return text.trim();
    }

    openArticle(item: any) {
        if (!item.isDummy)
            this.router.navigateByUrl(
                '/pressroom/' + item.pressroomId + '/article/' + item._id,
            );
    }
}
