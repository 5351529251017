<section class="error-page">
    <div class="div-card">
        <img
            class="image"
            src="./../../../assets/icons/404.svg"
            loading="lazy"
            alt="404 page"
            height="500px"
        />
        <div class="title">Oops! This Page Could Not Be Found</div>
        <div class="subtitle">
            Sorry but the page you are looking for does not exist, have been
            removed. name changed or is temporarily unavailable
        </div>
    </div>
</section>
