import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    Renderer2,
    OnInit,
} from '@angular/core';

@Directive({
    selector: '[appLazyLoad]',
})
export class LazyLoadDirective implements OnInit {
    @Input() appLazyLoad: string = '';

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
    ) {}

    @HostListener('load')
    onLoad() {
        this.renderer.removeClass(this.el.nativeElement, 'lazy-loading');
    }

    ngOnInit() {
        this.setImage(this.appLazyLoad);
    }

    private setImage(src: string) {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    this.renderer.setAttribute(
                        this.el.nativeElement,
                        'src',
                        src,
                    );
                    observer.unobserve(this.el.nativeElement);
                }
            });
        });
        observer.observe(this.el.nativeElement);
    }
}
