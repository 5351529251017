<div *ngIf="isLoading">
    <div class="loaderMain">
        <div class="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div>

<div class="site-container" id="liveSiteContent">
    <div class="toolbar" id="siteToolbarMain" style="height: 10vh">
        <mat-toolbar
            class="site-header-container"
            id="liveSiteHeader"
            [ngClass]="{ sticky: isSticky, 'un-sticky': isUnsticky }"
            [ngStyle]="headerFont?.getStyles()"
            *ngIf="!hideOps.header"
        >
            <span class="site-header-title" id="liveSiteHeaderTitle">
                Blumepress
            </span>
            <div
                class="headerLogo"
                id="headerLogo"
                (click)="goToSite()"
                style="cursor: pointer"
            ></div>
            <div *ngIf="searchBarVisible" class="search-bar-container">
                <div class="container-input" style="margin-right: 10px">
                    <input
                        matInput
                        type="text"
                        [placeholder]="contentTitle"
                        [matAutocomplete]="auto"
                        name="searchBox"
                        class="search-input"
                        (keyup)="onSearchBoxInput($event)"
                        autofocus
                    />
                </div>
                <div>
                    <mat-autocomplete
                        #auto="matAutocomplete"
                        [ngStyle]="{ 'border-radius': '1em' }"
                    >
                        <mat-option
                            (click)="openArticle(item)"
                            *ngFor="let item of filteredArticles"
                            [value]="item.title"
                            style="width: 100%"
                        >
                            <div class="searchCard">
                                <div
                                    *ngIf="item.image_URL"
                                    class="searchCardImage"
                                    [ngStyle]="{
                                        'background-image':
                                            'url(' + item.image_URL + ')'
                                    }"
                                ></div>
                                <div
                                    *ngIf="!item.image_URL"
                                    class="searchCardImage"
                                    [ngStyle]="{
                                        'background-image':
                                            'url(' + defaultSearchImage + ')'
                                    }"
                                ></div>
                                <div class="searchCardText">
                                    <div class="searchCardTitle line-limit-2">
                                        {{ item.title }}
                                    </div>
                                    <div
                                        class="searchCardIngress line-limit-3"
                                        style="width: 100%"
                                        [innerText]="
                                            htmlToPlaintext(
                                                item?.ingress || item.main_Body
                                            ) | slice: 0 : 180
                                        "
                                    >
                                        ...
                                    </div>
                                </div>
                            </div>
                            <mat-divider
                                style="width: 98%; margin: 5px"
                            ></mat-divider>
                        </mat-option>
                        <mat-option
                            style="width: 100%"
                            *ngIf="isArticleFetching"
                        >
                            <div class="searchCard">
                                <ngx-skeleton-loader
                                    style="width: 100%"
                                    [theme]="{
                                        width: '100%',
                                        height: '150px',
                                        'border-radius': '1.2rem',
                                        margin: '5px',
                                        'background-color': 'whitesmoke'
                                    }"
                                ></ngx-skeleton-loader>
                            </div>
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>

            <div *ngIf="menuFlag">
                <div
                    class="liveSiteNav"
                    [ngStyle]="{
                        color: navTextColor
                    }"
                    fxLayout
                    fxLayoutGap="30px"
                    [style]="
                        '--current-text-color : ' + navTextColor
                            | sanitizeHtml: 'style'
                    "
                >
                    <button
                        mat-mini-fab
                        [ngStyle]="{
                            'background-color': navButtonBgColor,
                            color: navTextColor
                        }"
                        style="margin-right: 5px; box-shadow: none !important"
                        (click)="toggleSearch()"
                    >
                        <mat-icon>search</mat-icon>
                    </button>
                    <span
                        (click)="navigatePage('home')"
                        style="cursor: pointer"
                    >
                        <span *ngIf="navItems.navItem1Name" class="nav-item">
                            <button
                                mat-flat-button
                                [ngStyle]="{
                                    color: navTextColor,
                                    'background-color': navButtonBgColor
                                }"
                            >
                                <p class="nav-text">
                                    {{ navItems.navItem1Name }}
                                </p>
                            </button>
                        </span>
                        <span *ngIf="!navItems.navItem1Name">
                            <p class="nav-text">Home</p>
                        </span>
                    </span>
                    <span
                        (click)="navigatePage('news')"
                        style="cursor: pointer"
                    >
                        <span *ngIf="navItems.navItem2Name" class="nav-item">
                            <button
                                mat-flat-button
                                [ngStyle]="{
                                    color: navTextColor,
                                    'background-color': navButtonBgColor
                                }"
                            >
                                <p class="nav-text">
                                    {{ navItems.navItem2Name }}
                                </p>
                            </button>
                        </span>
                        <span *ngIf="!navItems.navItem2Name">
                            <p class="nav-text">News</p>
                        </span>
                    </span>
                    <span
                        *ngIf="!hideGallary"
                        (click)="navigatePage('media')"
                        style="cursor: pointer"
                    >
                        <span *ngIf="navItems.navItem3Name" class="nav-item">
                            <button
                                mat-flat-button
                                [ngStyle]="{
                                    color: navTextColor,
                                    'background-color': navButtonBgColor
                                }"
                            >
                                <p class="nav-text">
                                    {{ navItems.navItem3Name }}
                                </p>
                            </button>
                        </span>
                        <span *ngIf="!navItems.navItem3Name">
                            <p class="nav-text">Media Albums</p>
                        </span>
                    </span>

                    <span
                        *ngIf="
                            navItems.navItem4Name !== '' &&
                            navItems.navItem4Name &&
                            navItems.navItem4Href !== '' &&
                            navItems.navItem4Href
                        "
                        (click)="navigatePageCustom(navItems.navItem4Href)"
                        style="cursor: pointer"
                    >
                        <span class="nav-item">
                            <button
                                mat-flat-button
                                [ngStyle]="{
                                    color: navTextColor,
                                    'background-color': navButtonBgColor
                                }"
                            >
                                <p class="nav-text">
                                    {{ navItems.navItem4Name }}
                                </p>
                            </button>
                        </span>
                    </span>
                    <span
                        *ngIf="
                            navItems.navItem5Name !== '' &&
                            navItems.navItem5Name &&
                            navItems.navItem5Href !== '' &&
                            navItems.navItem5Href
                        "
                        (click)="navigatePageCustom(navItems.navItem5Href)"
                        style="cursor: pointer"
                    >
                        <span class="nav-item">
                            <button
                                mat-flat-button
                                [ngStyle]="{
                                    color: navTextColor,
                                    'background-color': navButtonBgColor
                                }"
                            >
                                <p class="nav-text">
                                    {{ navItems.navItem5Name }}
                                </p>
                            </button>
                        </span>
                    </span>
                    <span
                        *ngIf="
                            navItems.navItem6Name !== '' &&
                            navItems.navItem6Name &&
                            navItems.navItem6Href !== '' &&
                            navItems.navItem6Href
                        "
                        (click)="navigatePageCustom(navItems.navItem6Href)"
                        style="cursor: pointer"
                    >
                        <span class="nav-item">
                            <button
                                mat-flat-button
                                [ngStyle]="{
                                    color: navTextColor,
                                    'background-color': navButtonBgColor
                                }"
                            >
                                <p class="nav-text">
                                    {{ navItems.navItem6Name }}
                                </p>
                            </button>
                        </span>
                    </span>
                    <span
                        *ngIf="
                            buttonsConfigs &&
                            buttonsConfigs?.followShowOnNavigation
                        "
                        style="cursor: pointer"
                    >
                        <span class="nav-item">
                            <button
                                mat-button
                                (click)="subscribePressroom()"
                                [ngStyle]="{
                                    color:
                                        buttonsConfigs?.followTextColor ??
                                        '#000',
                                    'background-color':
                                        buttonsConfigs?.followBgColor ?? '#fff',
                                    'border-radius':
                                        buttonsConfigs?.followButtonType === 1
                                            ? '2em'
                                            : '0.2em'
                                }"
                            >
                                <p class="nav-text">
                                    {{
                                        buttonsConfigs?.followDisplayText ??
                                            'Subscribe'
                                    }}
                                </p>
                            </button>
                        </span>
                    </span>
                </div>
            </div>
            <div *ngIf="!menuFlag">
                <mat-icon
                    [ngStyle]="{ color: navTextColor }"
                    [matMenuTriggerFor]="menu"
                    #clickMenuTrigger="matMenuTrigger"
                    style="
                        cursor: pointer;
                        font-size: 42px;
                        width: 50px;
                        height: 50px;
                    "
                >
                    menu
                </mat-icon>
                <mat-menu
                    #menu="matMenu"
                    [ngStyle]="{ display: menuFlag }"
                    class="menuContent"
                >
                    <button
                        mat-menu-item
                        (click)="navigatePage('home')"
                        style="justify-content: center; text-align: center"
                    >
                        {{ navItems.navItem1Name }}
                    </button>
                    <button
                        mat-menu-item
                        (click)="navigatePage('news')"
                        style="justify-content: center; text-align: center"
                    >
                        {{ navItems.navItem2Name }}
                    </button>
                    <button
                        *ngIf="!hideGallary"
                        mat-menu-item
                        (click)="navigatePage('media')"
                        style="justify-content: center; text-align: center"
                    >
                        {{ navItems.navItem3Name }}
                    </button>

                    <button
                        *ngIf="
                            navItems.navItem4Name !== '' &&
                            navItems.navItem4Name &&
                            navItems.navItem4Href !== '' &&
                            navItems.navItem4Href
                        "
                        mat-menu-item
                        (click)="navigatePageCustom(navItems.navItem4Href)"
                        style="justify-content: center; text-align: center"
                    >
                        {{ navItems.navItem4Name }}
                    </button>
                    <button
                        *ngIf="
                            navItems.navItem5Name !== '' &&
                            navItems.navItem5Name &&
                            navItems.navItem5Href !== '' &&
                            navItems.navItem5Href
                        "
                        mat-menu-item
                        (click)="navigatePageCustom(navItems.navItem5Href)"
                        style="justify-content: center; text-align: center"
                    >
                        {{ navItems.navItem5Name }}
                    </button>
                    <button
                        *ngIf="
                            navItems.navItem6Name !== '' &&
                            navItems.navItem6Name &&
                            navItems.navItem6Href !== '' &&
                            navItems.navItem6Href
                        "
                        mat-menu-item
                        (click)="navigatePageCustom(navItems.navItem6Href)"
                        style="justify-content: center; text-align: center"
                    >
                        {{ navItems.navItem6Name }}
                    </button>

                    <button
                        *ngIf="
                            buttonsConfigs &&
                            buttonsConfigs?.followShowOnNavigation
                        "
                        (click)="subscribePressroom()"
                        mat-menu-item
                        style="justify-content: center; text-align: center"
                    >
                        {{ buttonsConfigs?.followDisplayText ?? 'Subscribe' }}
                    </button>
                </mat-menu>
            </div>
        </mat-toolbar>
    </div>
    <router-outlet @fadeInOut></router-outlet>
    <div class="liveSiteFooter">
        <div
            class="contact-footer"
            [ngStyle]="contactfooterConfig()"
            *ngIf="!hideOps.contactPerson"
        >
            <div class="end-footer-contact">
                <div class="contact-header">
                    <span
                        class="separator"
                        [ngStyle]="{ 'background-color': typeTextColor }"
                    ></span>
                    <h1>{{ contactTitle }}</h1>
                </div>
                <div>
                    <div>
                        <img
                            class="contact-image"
                            src="{{ contactImage }}"
                            loading="lazy"
                            alt="{{ contactImageTooltipAltText }}"
                            title="{{ contactImageTooltipAltText }}"
                        />
                    </div>
                    <div>
                        <div
                            class="title"
                            [ngStyle]="{
                                color: typeTextColor
                            }"
                        >
                            {{ contactDesignation }}
                        </div>

                        <h2 class="contact-name">
                            {{ contactName }}
                        </h2>
                        <div style="text-align: left">
                            <span
                                *ngIf="contactEmail"
                                [ngStyle]="{
                                    color: contactfooterFontColor
                                }"
                            >
                                <i
                                    class="material-icons"
                                    style="vertical-align: middle"
                                >
                                    mail_outline
                                </i>
                                &nbsp;
                                <a
                                    style="cursor: pointer"
                                    href="mailto:{{ contactEmail }}"
                                    target="_top"
                                    style="
                                        vertical-align: middle;
                                        text-decoration: none;
                                        color: inherit;
                                    "
                                >
                                    {{ contactEmail }}
                                </a>
                            </span>
                            <br />
                            <span
                                *ngIf="contactPhone"
                                [ngStyle]="{
                                    color: contactfooterFontColor
                                }"
                            >
                                <i
                                    class="material-icons"
                                    style="vertical-align: middle"
                                >
                                    call
                                </i>
                                &nbsp;
                                <a
                                    style="cursor: pointer"
                                    href="tel:{{ contactPhone }}"
                                    target="_top"
                                    style="
                                        vertical-align: middle;
                                        text-decoration: none;
                                        color: inherit;
                                    "
                                >
                                    {{ contactPhone }}
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="contact-footer"
            [ngStyle]="contactfooterConfig()"
            *ngIf="!hideOps.contactList && !isAllHiddenContact"
        >
            <div class="end-footer-contact" *ngIf="!hideOps.contactList">
                <div class="contact-header" *ngIf="contactTitle.length > 0">
                    <span
                        class="separator"
                        *ngIf="contactTitle && separatorStyle === 1"
                        [ngStyle]="{ 'background-color': typeTextColor }"
                    ></span>
                    <h1>{{ contactTitle }}</h1>
                    <span
                        class="separator-2"
                        *ngIf="contactTitle && separatorStyle === 2"
                        [ngStyle]="{ 'background-color': typeTextColor }"
                    ></span>
                </div>
                <div class="contact-list">
                    <ng-container
                        *ngFor="let contact of contactPersons; let i = index"
                    >
                        <div
                            [ngStyle]="contactPersonConfig(contact)"
                            *ngIf="!contact.hide"
                        >
                            <div class="list">
                                <div
                                    *ngIf="
                                        contact.contactImage;
                                        else defaultAvatar
                                    "
                                >
                                    <img
                                        class="contact-image"
                                        [src]="contact.contactImage"
                                        loading="lazy"
                                        [alt]="contact.contactTooltipAltText"
                                        [title]="contact.contactTooltipAltText"
                                        [ngStyle]="{
                                            height: contact.contactImageHeight
                                        }"
                                    />
                                </div>
                                <ng-template #defaultAvatar>
                                    <div>
                                        <span
                                            class="default-avatar"
                                            [ngStyle]="{
                                                height: contact.contactImageHeight,
                                                width: contact.contactImageHeight,
                                                'background-color':
                                                    typeTextColor,
                                                color:
                                                    contactfooterBGColor ||
                                                    'whitesmoke'
                                            }"
                                        >
                                            {{
                                                contact.contactPersonName.charAt(
                                                    0
                                                )
                                            }}
                                        </span>
                                    </div>
                                </ng-template>
                                <div>
                                    <div
                                        class="title"
                                        [ngStyle]="{
                                            color: typeTextColor
                                        }"
                                    >
                                        {{
                                            contact.contactPersonDesignation ||
                                                'Desgination'
                                        }}
                                    </div>
                                    <h2 class="contact-name">
                                        {{ contact.contactPersonName }}
                                    </h2>
                                    <div style="text-align: left">
                                        <span
                                            *ngIf="contact.contactPersonEmail"
                                            [ngStyle]="{
                                                color: contact.contactFontColor
                                            }"
                                            style="
                                                margin-bottom: 5px;
                                                margin-top: 5px;
                                                display: inline-block;
                                            "
                                        >
                                            <mat-icon
                                                class="material-icons"
                                                style="vertical-align: middle"
                                                svgIcon="custom-email"
                                            ></mat-icon>
                                            &nbsp;
                                            <a
                                                style="cursor: pointer"
                                                href="mailto:{{
                                                    contact.contactPersonEmail
                                                }}"
                                                target="_top"
                                                style="
                                                    vertical-align: middle;
                                                    text-decoration: none;
                                                    color: inherit;
                                                "
                                            >
                                                {{ contact.contactPersonEmail }}
                                            </a>
                                        </span>
                                        <br />
                                        <span
                                            *ngIf="contact.contactPersonPhone"
                                            [ngStyle]="{
                                                color: contact.contactFontColor
                                            }"
                                        >
                                            <mat-icon
                                                class="material-icons"
                                                style="vertical-align: middle"
                                                svgIcon="custom-call"
                                            ></mat-icon>
                                            &nbsp;
                                            <a
                                                style="cursor: pointer"
                                                href="tel:{{
                                                    contact.contactPersonPhone
                                                }}"
                                                target="_top"
                                                style="
                                                    vertical-align: middle;
                                                    text-decoration: none;
                                                    color: inherit;
                                                "
                                            >
                                                {{ contact.contactPersonPhone }}
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div
            class="powered-by-footer"
            [ngStyle]="aboutfooterConfig()"
            *ngIf="!hideOps.aboutUs"
        >
            <div
                class="end-footer-about"
                [style]="
                    '--secondary-footer-color: ' + aboutUs?.secondaryBgColor ||
                        'grey' | sanitizeHtml: 'style'
                "
            >
                <footer class="footer-distributed">
                    <div class="footer-left">
                        <span
                            class="liveSiteHeaderTitle"
                            id="liveSiteFooterTitle"
                        >
                            Blumepress
                        </span>
                        <div
                            class="headerLogo"
                            id="footerLogo"
                            (click)="goToSite()"
                            style="cursor: pointer"
                        ></div>

                        <p class="footer-links">
                            <span
                                (click)="navigatePage('home')"
                                style="cursor: pointer"
                            >
                                <a
                                    *ngIf="navItems.navItem1Name"
                                    class="nav-item"
                                >
                                    {{ navItems.navItem1Name }}
                                </a>
                                <a *ngIf="!navItems.navItem1Name">Home</a>
                            </span>
                            <span
                                (click)="navigatePage('news')"
                                style="cursor: pointer"
                            >
                                <a
                                    *ngIf="navItems.navItem2Name"
                                    class="nav-item"
                                >
                                    {{ navItems.navItem2Name }}
                                </a>
                                <a *ngIf="!navItems.navItem2Name">News</a>
                            </span>
                            <span
                                *ngIf="!hideGallary"
                                (click)="navigatePage('media')"
                                style="cursor: pointer"
                            >
                                <a
                                    *ngIf="navItems.navItem3Name"
                                    class="nav-item"
                                >
                                    {{ navItems.navItem3Name }}
                                </a>
                                <a *ngIf="!navItems.navItem3Name">
                                    Media Gallery
                                </a>
                            </span>

                            <span
                                *ngIf="
                                    navItems.navItem4Name !== '' &&
                                    navItems.navItem4Name &&
                                    navItems.navItem4Href !== '' &&
                                    navItems.navItem4Href
                                "
                                (click)="
                                    navigatePageCustom(navItems.navItem4Href)
                                "
                                style="cursor: pointer"
                            >
                                <a class="nav-item">
                                    {{ navItems.navItem4Name }}
                                </a>
                            </span>
                            <span
                                *ngIf="
                                    navItems.navItem5Name !== '' &&
                                    navItems.navItem5Name &&
                                    navItems.navItem5Href !== '' &&
                                    navItems.navItem5Href
                                "
                                (click)="
                                    navigatePageCustom(navItems.navItem5Href)
                                "
                                style="cursor: pointer"
                            >
                                <a class="nav-item">
                                    {{ navItems.navItem5Name }}
                                </a>
                            </span>
                            <span
                                *ngIf="
                                    navItems.navItem6Name !== '' &&
                                    navItems.navItem6Name &&
                                    navItems.navItem6Href !== '' &&
                                    navItems.navItem6Href
                                "
                                (click)="
                                    navigatePageCustom(navItems.navItem6Href)
                                "
                                style="cursor: pointer"
                            >
                                <a class="nav-item">
                                    {{ navItems.navItem6Name }}
                                </a>
                            </span>
                        </p>
                        <div
                            *ngIf="
                                buttonsConfigs &&
                                buttonsConfigs?.followShowOnFooter
                            "
                            style="cursor: pointer"
                        >
                            <span class="nav-item">
                                <button
                                    mat-button
                                    (click)="subscribePressroom()"
                                    [ngStyle]="{
                                        color:
                                            buttonsConfigs?.followTextColor ??
                                            '#000',
                                        'background-color':
                                            buttonsConfigs?.followBgColor ??
                                            '#fff',
                                        'border-radius':
                                            buttonsConfigs?.followButtonType ===
                                            1
                                                ? '2em'
                                                : '0.2em'
                                    }"
                                >
                                    <p class="nav-text">
                                        {{
                                            buttonsConfigs?.followDisplayText ??
                                                'Subscribe'
                                        }}
                                    </p>
                                </button>
                            </span>
                        </div>
                        <div class="blumepress-powered">
                            <a
                                *ngIf="hidePoweredBy"
                                target="_blank"
                                href="https://blumepress.com/"
                                [ngStyle]="{ color: aboutfooterFontColor }"
                            >
                                <p>
                                    <mat-icon
                                        class="iconItem"
                                        [ngStyle]="{
                                            color: aboutfooterFontColor
                                        }"
                                        svgIcon="blumepress"
                                    ></mat-icon>
                                    Powered by Blumepress
                                </p>
                            </a>
                        </div>
                    </div>

                    <div class="footer-center">
                        <div *ngIf="aboutUs?.aboutAddress">
                            <i>
                                <mat-icon>location_on</mat-icon>
                            </i>
                            <p>
                                <span>
                                    <div id="companyName">
                                        {{ aboutUs?.aboutCompanyName }}
                                    </div>
                                </span>
                                <span>
                                    <div id="companyAddress">
                                        {{ aboutUs?.aboutAddress }}
                                    </div>
                                </span>
                            </p>
                        </div>

                        <div *ngIf="aboutUs?.aboutPhone">
                            <i>
                                <mat-icon>phone</mat-icon>
                            </i>
                            <p id="companyPhone">
                                <a
                                    style="cursor: pointer"
                                    href="tel:{{ aboutUs?.aboutPhone }}"
                                    target="_top"
                                    style="
                                        vertical-align: middle;
                                        text-decoration: none;
                                        color: inherit;
                                    "
                                >
                                    {{ aboutUs?.aboutPhone }}
                                </a>
                            </p>
                        </div>

                        <div *ngIf="aboutUs?.aboutEmail">
                            <i class="fa-envelope">
                                <mat-icon>email</mat-icon>
                            </i>
                            <p id="companyEmail">
                                <a
                                    style="cursor: pointer"
                                    href="mailto:{{ aboutUs?.aboutEmail }}"
                                    target="_top"
                                    style="
                                        text-decoration: none;
                                        color: inherit;
                                    "
                                >
                                    {{ aboutUs?.aboutEmail }}
                                </a>
                            </p>
                        </div>
                    </div>

                    <div class="footer-right">
                        <p class="footer-company-about">
                            <span>
                                {{
                                    aboutUs?.customCompanyHeader ||
                                        'Get In Touch'
                                }}
                            </span>
                            {{
                                aboutUs?.customCompanyDescription === undefined
                                    ? customFooterDescription
                                    : aboutUs?.customCompanyDescription
                            }}
                        </p>

                        <div class="footer-icons">
                            <span *ngIf="facebookLink" (click)="facebook()">
                                <mat-icon
                                    class="iconItem"
                                    svgIcon="{{ iconSet }}_facebook"
                                ></mat-icon>
                            </span>
                            <span *ngIf="twitterLink" (click)="twitter()">
                                <mat-icon
                                    class="iconItem"
                                    svgIcon="{{ iconSet }}_x"
                                ></mat-icon>
                            </span>
                            <span *ngIf="instagramLink" (click)="instagram()">
                                <mat-icon
                                    class="iconItem"
                                    svgIcon="{{ iconSet }}_instagram"
                                ></mat-icon>
                            </span>
                            <span *ngIf="youtubeLink" (click)="youtube()">
                                <mat-icon
                                    class="iconItem"
                                    svgIcon="{{ iconSet }}_youtube"
                                ></mat-icon>
                            </span>
                            <span *ngIf="linkedinLink" (click)="linkedin()">
                                <mat-icon
                                    class="iconItem"
                                    svgIcon="{{ iconSet }}_linkedin"
                                ></mat-icon>
                            </span>
                            <span *ngIf="rssLink" (click)="rss()">
                                <mat-icon
                                    class="iconItem"
                                    style="width: 1.8em"
                                    svgIcon="{{ iconSet }}_rss"
                                ></mat-icon>
                            </span>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    </div>
</div>
<div class="fab-container" *ngIf="isUnsticky && !hideBackToTop" @fadeInOut>
    <button
        mat-fab
        (click)="backToTop()"
        [ngStyle]="{ 'background-color': primaryBgcolor }"
        class="back-to-top"
    >
        <mat-icon [ngStyle]="{ color: primaryFontcolor }">
            keyboard_arrow_up
        </mat-icon>
    </button>
</div>
