import { AfterContentInit, Component, OnInit } from '@angular/core';
import { FeedService } from './feed.service';

@Component({
    selector: 'app-feed',
    templateUrl: './feed.component.html',
    styleUrl: './feed.component.scss',
})
export class FeedComponent implements AfterContentInit {
    rssFeed!: any;
    constructor(private feedService: FeedService) {}

    ngAfterContentInit() {
        this.feedService.getRssContent().subscribe((val) => {
            this.rssFeed = val;
        });
    }
}
