<div class="main-back">
    <mat-card>
        <mat-card-header>
            <mat-progress-bar
                *ngIf="isloading"
                mode="indeterminate"
                color="primary"
                class="progressBar"
            ></mat-progress-bar>
        </mat-card-header>
        <img
            src="./../../assets/icons/blumepress.svg"
            height="90"
            style="display: flex; justify-content: center; width: 100%"
        />
        <div class="bp_login_alert" *ngIf="bpAlert === true">
            {{ messageAlert }}
        </div>
        <mat-card-content [ngStyle]="{ opacity: opacityLevel }">
            <form class="login-form">
                <mat-form-field class="login-full-width" appearance="outline">
                    <mat-label>Email</mat-label>
                    <input
                        matInput
                        placeholder="Enter email address"
                        [formControl]="loginFormControl"
                        #userEmail
                        required
                    />
                    <mat-error
                        *ngIf="
                            loginFormControl.hasError('email') &&
                            !loginFormControl.hasError('required')
                        "
                    >
                        Please enter a valid email address
                    </mat-error>
                    <mat-error *ngIf="loginFormControl.hasError('required')">
                        Email is
                        <strong>required</strong>
                    </mat-error>
                </mat-form-field>
                <br />
                <br />
                <mat-form-field class="login-full-width" appearance="outline">
                    <mat-label>Password</mat-label>
                    <input
                        matInput
                        placeholder="Enter Password"
                        #userPassword
                        required
                        type="password"
                    />
                </mat-form-field>
                <mat-checkbox>
                    <span
                        style="
                            margin-left: 0.5em;
                            font-size: 16px;
                            font-weight: 100;
                        "
                    >
                        Remember me
                    </span>
                </mat-checkbox>
                <mat-card-actions class="card-actions">
                    <button
                        mat-button
                        color="primary"
                        class="login-button"
                        (click)="login(userEmail.value, userPassword.value)"
                    >
                        LOG IN
                    </button>

                    <p style="text-align: center">
                        <span
                            style="color: gray; cursor: pointer"
                            (click)="forgotPassword()"
                        >
                            <u>Reset password?</u>
                        </span>
                    </p>
                </mat-card-actions>
            </form>
        </mat-card-content>
    </mat-card>
    <router-outlet></router-outlet>
</div>
