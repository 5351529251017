import { ArticleService } from './article.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WebPageConfigService } from 'src/app/dashboard/website-config/website-config.service';
import { MatIconRegistry } from '@angular/material/icon';
import nodeHtmlParser from 'node-html-parser';

import { DomSanitizer, Meta } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { SeoService } from 'src/app/seo.service';
import { Font } from 'ngx-font-picker';
import { ArticleQueryParams, NewsService } from '../news/news.service';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-article',
    templateUrl: './article.component.html',
    styleUrl: './article.component.scss',
    providers: [Meta],
})
export class ArticleComponent implements OnInit {
    data: any;

    mainBody: any;
    uid: any;
    articleDateColor: any;
    articleTitleColor: any;
    articleIngressColor: any;
    articleAuthorColor: any;
    articleDateFormat: any;
    shareIconSet = 1;
    backIcon = 1;
    isNavLink = '';
    link: any;
    link1: any;
    link2: any;
    routerUrl: any;
    isBodyImgSame = false;
    articleDateLanguage = 'en';
    imageDisplay!: boolean;
    font: Font | any;
    hideDate: boolean = false;
    hideAuthor: boolean = false;
    hideImage: boolean = false;
    isArticleFound = true;
    articlePortfolio: any;
    typeTextColor: any;
    primaryBgColor: any;
    separatorStyle: any;

    isPrintArticleLoading = true;
    printArticle: any;
    articleCardConfig: any;
    recentArticlesSectionTitle: any;
    showRecentArticles: any;
    contentCategory!: any;
    analyticsUrl = '';

    constructor(
        private seo: SeoService,
        public articleService: ArticleService,
        private router: Router,
        private webService: WebPageConfigService,
        private iconRegistry: MatIconRegistry,
        private newsService: NewsService,
        private sanitizer: DomSanitizer,
        private location: Location,
        private route: ActivatedRoute,
    ) {
        iconRegistry.addSvgIcon(
            'blumepress',
            sanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/blumepress.svg',
            ),
        );
    }

    ngOnInit() {
        window.scrollTo({
            top: 0,
            left: 100,
            behavior: 'smooth',
        });

        this.route.params.subscribe((params: any) => {
            const articleId = params['id'];
            this.fetchArticle(articleId);
        });
        this.routerUrl = window.location.href;
        this.uid = this.router.parseUrl(
            this.router.url,
        ).root.children.primary.segments[1].path;

        this.webService.getConfig(this.uid).then((response: any) => {
            const {
                theme,
                footer,
                banner,
                buttonSectionConfig,
                header,
                articleCards,
                body,
                contentCategory,
            } = response;

            // Set theme colors
            this.typeTextColor = theme.secondaryBgColor;

            this.primaryBgColor = theme.primaryBgColor;

            // Footer configuration
            this.articlePortfolio = footer?.aboutUs?.aboutPortfolio ?? '';

            // Font configuration
            this.font = new Font(banner.bodyFont || theme.font);

            if (contentCategory) {
                this.contentCategory = contentCategory;
            }

            // Button section configuration
            this.hideDate = buttonSectionConfig?.hideDate ?? false;
            this.hideAuthor = buttonSectionConfig?.hideAuthor ?? false;
            this.hideImage = buttonSectionConfig?.hideImage ?? false;
            this.separatorStyle = buttonSectionConfig.separatorStyle ?? 1;

            // Header navigation links
            if (header) {
                this.isNavLink = '';
                const navLinks = [
                    'navItem3Href',
                    'navItem4Href',
                    'navItem5Href',
                ];
                navLinks.forEach((navItem) => {
                    if (header[navItem]) {
                        this.isNavLink += header[navItem] + '/';
                    }
                });
            }

            // Article cards configuration
            if (articleCards) {
                this.articleDateLanguage =
                    articleCards.articleDateLanguage ?? '';
                this.articleDateColor = articleCards.articleDateColor ?? '';
                this.articleTitleColor = articleCards.articleTitleColor ?? '';
                this.articleIngressColor =
                    articleCards.articleIngressColor ?? '';
                this.articleAuthorColor = articleCards.articleAuthorColor ?? '';
                this.shareIconSet = articleCards.shareIconSet ?? '';
                this.backIcon = articleCards.backIcon ?? '';
                this.articleDateFormat =
                    articleCards.articleDateFormat ?? 'fullDate';
                this.recentArticlesSectionTitle =
                    articleCards.recentArticlesSectionTitle;
                this.showRecentArticles = articleCards.showRecentArticles;
            }

            this.articleCardConfig = {
                cardDatecolor:
                    articleCards?.cardDatecolor ?? this.articleDateColor,
                cardTitlecolor:
                    articleCards?.cardTitlecolor ?? this.articleTitleColor,
                cardIngresscolor:
                    articleCards?.cardIngresscolor ?? this.articleIngressColor,
                cardArticlecolor: articleCards?.cardArticlecolor ?? '#000',
                articleDateLanguage: this.articleDateLanguage,
                cardImageStyle: articleCards?.cardImageStyle ?? 'cover',
                cardImageBackgroundColor:
                    articleCards?.cardImageBackgroundColor ?? '#000',
                cardAuthorColor:
                    articleCards?.cardAuthorColor ?? this.articleAuthorColor,
                defaultCardImage: articleCards?.defaultCardImage ?? '',
                hideAuthor: this.hideAuthor,
                viewMore: buttonSectionConfig?.viewMore ?? 'View More',
                viewMoreColor: buttonSectionConfig?.viewMoreColor ?? '#fff',
                backgroundStyleDynamic: '',
                typeTextColor: this.primaryBgColor,
                articleDateFormat: this.articleDateFormat,
            };

            // Set article container background color
            const articleContainer =
                document.getElementById('articleContainer');
            if (articleContainer) {
                articleContainer.style.backgroundColor = banner.override
                    ? body.bodyBgcolor
                    : theme.primaryBgColor;
            }
            if (this.showRecentArticles) this.loadArticles();
        });
    }

    async fetchArticle(articleId: any) {
        this.data = null;
        try {
            const val: any = await firstValueFrom(
                this.articleService.viewFullArticle(articleId),
            );

            if (!val && val.response.length < 1) {
                this.isArticleFound = false;
                return;
            }

            let node_root = nodeHtmlParser(val.response[0].main_Body);
            if (node_root.querySelectorAll('img').length) {
                this.isBodyImgSame = node_root
                    .querySelectorAll('img')
                    .map((img) => img.attributes.src)
                    .includes(val.response[0].image_URL);
            }

            this.data = val.response;
            if (this.data[0].imageWidth) {
                let width = this.data[0].imageWidth + '%';
                this.data[0].imageWidth = width;
            } else {
                this.data[0].imageWidth = '100%';
            }

            this.mainBody = this.data[0].main_Body;
            this.data[0].imageDisplay = false;

            const description = this.htmlToPlaintext(this.data[0].main_Body);

            this.seo.updateMetaInfo({
                title: this.data[0].title,
                description: description.substring(0, 200) + '...',
                imageUrl: this.data[0].image_URL ?? '',
                date: this.data[0].date ?? '',
            });
        } catch (error: any) {
            this.isArticleFound = false;
        }
        const path = '/pressroom/' + this.uid + '/article/' + articleId;
        setTimeout(() => {
            this.analyticsUrl = `${environment.url}api/v1/events${path}`;
        }, 5000);
    }

    async loadArticles(month = null, year = null) {
        this.isPrintArticleLoading = true;
        const articleQueryParams: ArticleQueryParams = {
            page: 1,
            itemsPerPage: 6,
            searchText: '',
            isVisible: true,
        };

        try {
            const val: any = await firstValueFrom(
                this.newsService.getArticles(articleQueryParams),
            );

            window.scrollTo({
                top: 0,
                left: 100,
                behavior: 'smooth',
            });

            this.printArticle = val.response;
            try {
                this.printArticle = this.printArticle.filter(
                    (article: any) => this.data[0]._id !== article._id,
                );
            } catch (error) {
                console.warn('Current article incuded in recent articles!');
            }

            this.isPrintArticleLoading = false;
        } catch (error) {
            console.error('Error loading articles', error);
            this.isPrintArticleLoading = false;
            this.router.navigateByUrl('/404');
        }
    }

    htmlToPlaintext(text: any) {
        try {
            const doc = new DOMParser().parseFromString(text, 'text/html');
            text = doc.body.textContent || '';
        } catch (ex) {}
        text = text.replace(/\s\s+/g, ' ');
        return text.trim();
    }

    cleanHTML(content: any) {
        let cleanContent;

        cleanContent = content.replace(
            /width\s*=(.*?)/g,
            "style='max-width: 100%;max-height: auto;' width=",
        );

        return cleanContent;
    }

    shareFB() {
        this.link = 'http://www.facebook.com/sharer.php?u=' + this.routerUrl;
    }

    shareLinkedIn() {
        this.link1 =
            'https://www.linkedin.com/shareArticle?mini=true&url=' +
            this.routerUrl;
    }

    shareTwitter() {
        this.link2 = 'https://x.com/intent/post?url=' + this.routerUrl;
    }

    returnBack() {
        this.location.back();
    }
}
