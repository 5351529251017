import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { AppService } from '../app.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    user$: any;
    constructor(private appService: AppService) {}
    public isAuthenticated(): boolean {
        let token = this.appService.getAuthToken || '';
        if (token === 'undefined' || token === null) {
            return false;
        } else {
            const decodeToken: any = jwtDecode(token);
            if (decodeToken.exp < new Date().getTime()) {
                return false;
            } else {
                return true;
            }
        }
    }

    public isPasswordUpdated(): any {
        const token = this.appService.getAuthToken;
        if (token === 'undefined' || token === null) {
            return false;
        } else {
            const decodeToken: any = jwtDecode(token);
            if (decodeToken.user.isPasswordUpdated === false) {
                return false;
            } else {
                return true;
            }
        }
    }

    public getToken(): string {
        return this.appService.getAuthToken;
    }
}
