// Service File for  fetching single page article content
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ArticleService {
    constructor(
        public route: Router,
        public http: HttpClient,
    ) {}
    viewFullArticle(articleId: any) {
        // Get the article Id form the Page Route
        const url = environment.url + 'api/v1/public/article/' + articleId;
        return this.http
            .get<any>(url, {
                headers: {
                    responseType: 'json',
                },
            })
            .pipe();
    }
}
