<div class="content-container" id="pressreleases" [ngStyle]="font?.getStyles()">
    <div class="container">
        <div class="row">
            <div class="header-container margin-bottom order-first">
                <div class="flex-row-sb" *ngIf="newsHeaderTitle" @fadeInOut>
                    <div class="back-buttton-header">
                        <div
                            class="container back-button"
                            *ngIf="!backButtonNews"
                        >
                            <mat-icon
                                [ngStyle]="{ color: headerColor }"
                                class="icon-item"
                                svgIcon="{{ backButtonStyle }}_return"
                                (click)="returnBack()"
                            ></mat-icon>
                        </div>
                        <h1
                            *ngIf="newsHeaderTitle"
                            class="d-flex flex-wrap align-items-center text-20 text-bold m-0"
                            id="latestNews"
                            [ngStyle]="{ color: headerColor }"
                        >
                            {{ newsHeaderTitle }}
                        </h1>
                    </div>
                    <div class="filter-dropdowns" *ngIf="enableFilters">
                        <div class="container-input" style="margin-right: 10px">
                            <input
                                type="text"
                                [(ngModel)]="searchTextInput"
                                name="text"
                                class="input"
                                (keyup)="onSearchBoxInput($event)"
                            />
                            <mat-icon class="mat-icon">search</mat-icon>
                        </div>

                        <label class="dropdown" style="margin-right: 10px">
                            <div class="dd-button">
                                {{
                                    selectedMonth
                                        ? getMonths(articleDateLanguage)[
                                              selectedMonth - 1
                                          ]
                                        : monthTitleText
                                }}
                            </div>
                            <input
                                type="checkbox"
                                class="dd-input"
                                id="month-dropdown"
                            />
                            <ul class="dd-menu">
                                <li (click)="selectMonth(null)">
                                    {{ monthTitleText }}
                                </li>
                                <li
                                    *ngFor="
                                        let month of getMonths(
                                            articleDateLanguage
                                        );
                                        let i = index
                                    "
                                    (click)="selectMonth(i + 1)"
                                >
                                    {{ month.toString() | titlecase }}
                                </li>
                            </ul>
                        </label>

                        <label class="dropdown">
                            <div class="dd-button">
                                {{
                                    selectedYear ? selectedYear : yearTitleText
                                }}
                            </div>
                            <input
                                type="checkbox"
                                class="dd-input"
                                id="year-dropdown"
                            />
                            <ul class="dd-menu">
                                <li (click)="selectYear(null)">
                                    {{ yearTitleText }}
                                </li>
                                <li
                                    *ngFor="let year of getLastFiveYears()"
                                    (click)="selectYear(year)"
                                >
                                    {{ year }}
                                </li>
                            </ul>
                        </label>
                    </div>
                </div>
                <div
                    style="display: flex; justify-content: space-between"
                    *ngIf="!newsHeaderTitle"
                >
                    <span style="width: 300px">
                        <ngx-skeleton-loader
                            [theme]="{
                                width: '100%',
                                height: '50px'
                            }"
                        ></ngx-skeleton-loader>
                    </span>
                    <span style="width: 300px">
                        <ngx-skeleton-loader
                            [theme]="{
                                width: '100%',
                                height: '50px'
                            }"
                        ></ngx-skeleton-loader>
                    </span>
                </div>
                <mat-divider
                    *ngIf="newsHeaderTitle !== ''"
                    style="margin-top: 10px"
                ></mat-divider>
            </div>
            <div class="article-and-image-container">
                <div
                    class="col-lg-9 order-last order-lg-first"
                    *ngIf="printArticle"
                    id="news-list"
                >
                    <div
                        *ngFor="
                            let item of printArticle
                                | paginate
                                    : {
                                          itemsPerPage: itemsPerPage,
                                          currentPage: currentPage,
                                          totalItems
                                      }
                        "
                        class="itemList"
                        infinite-scroll
                        [hidden]="isPrintArticleLoading"
                    >
                        <div
                            class="item"
                            (click)="openArticle(item)"
                            [ngStyle]="{
                                'background-color': newsArticleBgColor
                            }"
                        >
                            <div
                                *ngIf="!hideDate"
                                class="published"
                                [ngStyle]="{ color: articleDateColor }"
                            >
                                {{
                                    item.date
                                        | date
                                            : articleDateFormat
                                            : ''
                                            : articleDateLanguage
                                }}
                            </div>
                            <span
                                style="font-size: small; margin-left: 5px"
                                *ngIf="contentCategory?.showOnNews"
                            >
                                <app-category-chip
                                    [categoryId]="item.categoryId"
                                    [categories]="
                                        contentCategory.categories ?? []
                                    "
                                    [bgColor]="
                                        contentCategory?.categoryBackground
                                    "
                                    [textColor]="
                                        contentCategory?.categoryTextColor
                                    "
                                ></app-category-chip>
                            </span>
                            <a style="cursor: pointer">
                                <h1
                                    class="title"
                                    [ngStyle]="{ color: articleTitleColor }"
                                >
                                    {{ item.title }}
                                </h1>
                            </a>
                            <div
                                *ngIf="!hideAuthor"
                                class="author color-newsroom-accent-color"
                            >
                                <span
                                    [ngStyle]="{
                                        color: articleAuthorColor
                                    }"
                                >
                                    {{ item.author | slice: 0 : 25 }}
                                </span>
                            </div>
                            <div
                                *ngIf="!hideStartingText"
                                class="summary"
                                [ngStyle]="{ color: articleIngressColor }"
                            >
                                {{
                                    htmlToPlaintext(
                                        item?.ingress || item.main_Body
                                    ) | slice: 0 : 180
                                }}
                                <span
                                    *ngIf="
                                        !hideReadMore &&
                                        !hideStartingText &&
                                        (item?.ingress?.length >= 180 ||
                                            item?.main_Body?.length >= 180)
                                    "
                                    class="read-more-container"
                                >
                                    <a
                                        style="cursor: pointer"
                                        [ngStyle]="{
                                            color: readMoreColor
                                        }"
                                    >
                                        &nbsp;{{ readMore }}
                                    </a>
                                </span>
                            </div>

                            <div *ngIf="!hideImage" class="image-container">
                                <a style="cursor: pointer">
                                    <img
                                        class="image lazy-loading"
                                        *ngIf="item.image_URL"
                                        [appLazyLoad]="item.image_URL"
                                        loading="lazy"
                                        alt="content image"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- Skeleton Loader for Articles -->
                    <div
                        class="skeleton-container"
                        *ngIf="isPrintArticleLoading"
                        @fadeInOut
                    >
                        <ngx-skeleton-loader
                            *ngFor="let n of [].constructor(8); let i = index"
                            [theme]="{
                                width: '100%',
                                height: '400px',
                                'border-radius': '1.2rem',
                                margin: '1em 0'
                            }"
                        ></ngx-skeleton-loader>
                    </div>
                    <div
                        *ngIf="
                            printArticle.length === 0 && !isPrintArticleLoading
                        "
                        class="title no-content-found"
                        [ngStyle]="{ color: articleTitleColor }"
                        style="text-align: center"
                        @fadeInOut
                    >
                        No Content Found
                    </div>

                    <span
                        [style]="
                            '--current-text-color: ' + paginationTextColor
                                | sanitizeHtml: 'style'
                        "
                    ></span>
                    <pagination-controls
                        *ngIf="!isPrintArticleLoading"
                        class="pagination"
                        [style]="
                            '--current-bg-color : ' + typeTextColor
                                | sanitizeHtml: 'style'
                        "
                        [maxSize]="itemsPerPage"
                        [directionLinks]="directionLinks"
                        [responsive]="responsive"
                        [previousLabel]="paginationPreviousLabel"
                        [nextLabel]="paginationNextLabel"
                        (pageChange)="onPageChange($event)"
                        [totalItems]="totalItems"
                        [itemsPerPage]="itemsPerPage"
                        [currentPage]="currentPage"
                    ></pagination-controls>
                </div>

                <div class="right-side-container">
                    <div class="container" style="width: 98%; margin: 0.5em">
                        <div
                            class="category-items"
                            *ngIf="
                                !isCategoryFilterApplied &&
                                contentCategory?.showOnNewsSide
                            "
                        >
                            <mat-expansion-panel
                                [(expanded)]="panelOpenState"
                                (closed)="panelClosed()"
                                class="category-filter"
                            >
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        {{
                                            selectedCategoryName ||
                                                contentCategory.alterTextForAll ||
                                                'All'
                                        }}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <mat-selection-list
                                    disableRipple="true"
                                    [multiple]="false"
                                    hideSingleSelectionIndicator="true"
                                    [(ngModel)]="selectedCategory"
                                    (selectionChange)="fetchByCategory($event)"
                                >
                                    <mat-list-option
                                        value=""
                                        style="border-radius: 1em"
                                        [ngStyle]="{
                                            backgroundColor: isSelected('')
                                                ? contentCategory.categoryBackground
                                                : ''
                                        }"
                                    >
                                        <span
                                            [ngStyle]="{
                                                color: isSelected('')
                                                    ? contentCategory.categoryTextColor
                                                    : ''
                                            }"
                                        >
                                            {{
                                                contentCategory.alterTextForAll ||
                                                    'All'
                                            }}
                                        </span>
                                    </mat-list-option>
                                    <ng-container
                                        *ngFor="
                                            let category of contentCategory.categories ??
                                                []
                                        "
                                    >
                                        <mat-list-option
                                            *ngIf="category.visibleCounts >= 1"
                                            [value]="category.categoryId"
                                            [disabled]="
                                                category.visibleCounts < 1
                                            "
                                            style="border-radius: 1em"
                                            [ngStyle]="{
                                                backgroundColor: isSelected(
                                                    category.categoryId
                                                )
                                                    ? contentCategory.categoryBackground
                                                    : ''
                                            }"
                                        >
                                            <span
                                                [ngStyle]="{
                                                    color: isSelected(
                                                        category.categoryId
                                                    )
                                                        ? contentCategory.categoryTextColor
                                                        : ''
                                                }"
                                            >
                                                {{ category.categoryName }}

                                                <span
                                                    *ngIf="
                                                        contentCategory.showCounts &&
                                                        category.visibleCounts >
                                                            -1
                                                    "
                                                >
                                                    ({{
                                                        category.visibleCounts
                                                    }})
                                                </span>
                                            </span>
                                        </mat-list-option>
                                    </ng-container>
                                </mat-selection-list>
                            </mat-expansion-panel>
                        </div>
                        <div *ngIf="futureStories && isFutureStory">
                            <div class="row">
                                <div class="body-heading">
                                    <span
                                        class="separator"
                                        *ngIf="
                                            storySectionTitle &&
                                            separatorStyle === 1
                                        "
                                        [ngStyle]="{
                                            'background-color': typeTextColor
                                        }"
                                    ></span>
                                    <h1
                                        *ngIf="storySectionTitle !== ''"
                                        [ngStyle]="{ color: headerColor }"
                                    >
                                        {{ storySectionTitle }}
                                    </h1>
                                    <span
                                        class="separator-2"
                                        *ngIf="
                                            storySectionTitle &&
                                            separatorStyle === 2
                                        "
                                        [ngStyle]="{
                                            'background-color': typeTextColor
                                        }"
                                    ></span>
                                </div>
                            </div>
                            <app-future-story
                                [futureStories]="futureStories"
                                [config]="articleCardConfig"
                                [contentCategory]="contentCategory"
                            ></app-future-story>
                        </div>
                    </div>
                    <div
                        *ngIf="articlePortfolio?.showOnNews"
                        class="portfolio-container"
                    >
                        <div class="body-heading" [ngStyle]="font?.getStyles()">
                            <span
                                class="separator"
                                *ngIf="
                                    articlePortfolio?.portfolioSectionTitle &&
                                    separatorStyle === 1
                                "
                                [ngStyle]="{
                                    'background-color': typeTextColor
                                }"
                            ></span>
                            <h1
                                *ngIf="
                                    articlePortfolio.portfolioSectionTitle !==
                                    ''
                                "
                                [ngStyle]="{ color: headerColor }"
                            >
                                {{ articlePortfolio.portfolioSectionTitle }}
                            </h1>
                            <span
                                class="separator-2"
                                *ngIf="
                                    articlePortfolio?.portfolioSectionTitle &&
                                    separatorStyle === 2
                                "
                                [ngStyle]="{
                                    'background-color': typeTextColor,
                                    color: headerColor
                                }"
                            ></span>
                        </div>
                        <app-article-portfolio
                            class="portfolio-module"
                            [articlePortfolio]="articlePortfolio"
                            [typeTextColor]="typeTextColor"
                            [primaryBgColor]="primaryBgColor"
                            [bodyFont]="font"
                        ></app-article-portfolio>
                    </div>

                    <div
                        class="gallerize-images"
                        *ngIf="showGalleryonNews && gallaryImages.length > 0"
                    >
                        <div class="gallery-heading">
                            <span
                                class="separator"
                                *ngIf="gallaryTitle && separatorStyle === 1"
                                [ngStyle]="{
                                    'background-color': typeTextColor
                                }"
                            ></span>
                            <h1
                                *ngIf="gallaryTitle !== ''"
                                [ngStyle]="{
                                    color: headerColor
                                }"
                            >
                                {{ gallaryTitle }}
                            </h1>
                            <span
                                class="separator-2"
                                *ngIf="gallaryTitle && separatorStyle === 2"
                                [ngStyle]="{
                                    'background-color': typeTextColor
                                }"
                            ></span>
                        </div>
                        <app-albums
                            [galleryConfig]="gallaryImages"
                            [isLimited]="true"
                            [albumNameTextColor]="headerColor"
                        ></app-albums>
                    </div>
                </div>
                <img
                    [appLazyLoad]="analyticsUrl"
                    *ngIf="analyticsUrl"
                    loading="lazy"
                    class="analytics-image"
                />
            </div>
        </div>
    </div>
</div>
