<div class="container">
    <img
        src="./../../../assets/icons/blumepress.svg"
        height="90"
        style="display: flex; justify-content: center; width: 100%"
    />
    <h1>Reset Your Password</h1>
    <p>
        Please enter your email address below, and we will send you instructions
        on how to reset your password. Check your inbox for the next steps.
    </p>

    <mat-spinner
        *ngIf="spinner"
        style="margin: 0 auto; margin-top: 0em"
        [strokeWidth]="20"
        [diameter]="70"
    ></mat-spinner>
    <div [hidden]="spinner">
        <form [formGroup]="forgotPasswordForm">
            <mat-form-field class="formControl" appearance="outline">
                <mat-label>Email Id</mat-label>
                <input
                    matInput
                    type="email"
                    placeholder="Enter email"
                    formControlName="email"
                    required
                />
                <mat-error
                    *ngIf="
                        forgotPasswordForm.hasError('email') &&
                        !forgotPasswordForm.hasError('required')
                    "
                >
                    Please enter a valid email address.
                </mat-error>
                <mat-error *ngIf="forgotPasswordForm.hasError('required')">
                    Email is
                    <strong>required</strong>
                    .
                </mat-error>
            </mat-form-field>

            <div mat-dialog-actions class="dialogActions">
                <button mat-button color="warn" (click)="onCancel()">
                    Cancel
                </button>
                <button
                    mat-button
                    type="submit"
                    color="primary"
                    (click)="onSubmit()"
                    cdkFocusInitial
                >
                    Reset Password
                </button>
            </div>
        </form>
    </div>
</div>
