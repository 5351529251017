import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './login/login.service';
import { environment } from './../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AppInitService {
    constructor(
        private loginService: LoginService,
        private router: Router,
    ) {}

    initApp(): Promise<void> {
        return new Promise((resolve) => {
            const hostName = window.location.hostname;
            const pathName = window.location.pathname;
            if (
                hostName &&
                !hostName.includes('blumepress.com') &&
                !hostName.includes('localhost') &&
                (!pathName.includes('pressroom/') ||
                    !pathName.includes('/article/'))
            ) {
                this.loginService
                    .getDomainPressroomId(hostName)
                    .then((value: any) => {
                        const pressroomId = value?.pressroomId ?? '';
                        if (pressroomId) {
                            this.router.navigateByUrl(
                                `/pressroom/${pressroomId}/home`,
                            );
                        } else {
                            window.location.href = environment.main_url;
                        }
                    })
                    .catch((error) => {
                        console.warn('Check Hostname');
                    })
                    .finally(() => {
                        resolve();
                    });
            } else {
                resolve();
            }
        });
    }
}
