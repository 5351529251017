import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { Subject, filter, forkJoin, startWith, take } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';
import { LoginService } from 'src/app/login/login.service';
// import { CustomerAlertDialogComponent } from '../components/customer-alert-dialog/customer-alert-dialog.component';
import { DOCUMENT } from '@angular/common';
import { AuthRoles } from 'src/app/shared/enums/auth-roles.enum';
import {
    ACCESS_KEYS,
    LocalStorageService,
} from 'src/app/shared/services/local-storage.service';
import { AppService } from 'src/app/app.service';

type NavigationItem = {
    routerLink?: string;
    svgIcon?: string | any;
    title?: string;
    requiredRoles: AuthRoles[];
    children?: NavigationItem[];
    viewExpanded?: boolean;
    isDisabled?: boolean;
};

export type AssetIcon = {
    name: string;
    path: string;
};

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    isNavigationMenuOpen = false;
    navigationMenuWidth = 56;
    sidenavContentLeftMargin = 60;

    userName: string = '';
    fullName: string = '';
    emailId: string = '';
    currentRoute: string = '';

    accountMenu: any;
    accountName = 'Select Account';
    isMenuOpen: boolean | undefined;
    contentMargin = 56;
    pressroomId: any;
    navDest: any;
    iconList: HTMLElement | undefined;
    currentAccount: string;
    emailListener: string | undefined;
    isLoading: Boolean = false;
    isNewUser: Boolean = false;
    currentYear = new Date().getFullYear();

    allNavigationItems: NavigationItem[] = [
        {
            routerLink: '/dashboard/content-manager',
            svgIcon: 'content-manager',
            title: 'Content Manager',
            requiredRoles: [],
            isDisabled: false,
            viewExpanded: false,
        },
        {
            routerLink: '/dashboard/website-config',
            svgIcon: 'web-config',
            title: 'Pressroom Design',
            requiredRoles: [],
            isDisabled: false,
            viewExpanded: false,
        },
        {
            routerLink: '/settings',
            svgIcon: 'settings',
            title: 'Settings',
            requiredRoles: [AuthRoles.ADMIN, AuthRoles.SUPERADMIN],
            viewExpanded: true,
            isDisabled: false,
            children: [
                {
                    routerLink: '/settings/admin',
                    title: 'Account Admin',
                    isDisabled: false,
                    requiredRoles: [AuthRoles.ADMIN, AuthRoles.SUPERADMIN],
                },
                {
                    routerLink: '/settings/super-admin',
                    title: 'Super Admin',
                    isDisabled: false,
                    requiredRoles: [AuthRoles.SUPERADMIN],
                },
            ],
        },
    ];
    filteredNavigationItems: NavigationItem[] = [];

    icons: AssetIcon[] = [
        { name: 'avatar', path: 'assets/icons/avatar.svg' },
        { name: 'close', path: 'assets/icons/close.svg' },
        { name: 'dashboard', path: 'assets/icons/dashboard.svg' },
        { name: 'home', path: 'assets/icons/home.svg' },
        { name: 'blumepress', path: 'assets/icons/blumepress.svg' },
        { name: 'logo-v', path: 'assets/icons/logo-v.svg' },
        { name: 'menu', path: 'assets/icons/menu.svg' },
        { name: 'settings', path: 'assets/icons/settings.svg' },
        { name: 'content-manager', path: 'assets/icons/content-manager.svg' },
        { name: 'web-config', path: 'assets/icons/web-config.svg' },
        { name: 'noContent', path: 'assets/icons/noContent.svg' },

        //  Webconfig
        { name: 'bpTheme', path: 'assets/webConf/theme.svg' },
        { name: 'bpHeader', path: 'assets/webConf/header.svg' },
        { name: 'bpArticle', path: 'assets/webConf/article.svg' },
        { name: 'bpBody', path: 'assets/webConf/body.svg' },
        { name: 'bpFooter', path: 'assets/webConf/footer.svg' },
        { name: 'bpGallary', path: 'assets/webConf/gallary.svg' },
        { name: 'bpEmbed', path: 'assets/webConf/embed.svg' },
    ];
    currentUserRoles!: AuthRoles[];

    constructor(
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private router: Router,
        private auth: AuthService,
        public route: Router,
        public loginService: LoginService,
        private http: HttpClient,
        private localStorageService: LocalStorageService,
        private appService: AppService,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.icons.forEach((icon) => this.setupIcon(icon));
        const details: any = jwtDecode(
            this.localStorageService.getItem(ACCESS_KEYS.AUTH_TOKEN) || '',
        );
        this.userName = `${details.user.firstName[0]}${details.user.lastName[0]}`;
        this.fullName = `${details.user.firstName} ${details.user.lastName}`;
        this.emailId = details.user.email;
        this.currentUserRoles = details.user.roles || [];
        this.currentAccount =
            this.localStorageService.getItem(ACCESS_KEYS.CURRENT_ACCOUNT) || '';
        this.pressroomId =
            this.localStorageService.getItem(ACCESS_KEYS.CURRENT_PRESSROOM) ||
            '';
        this.navDest = this.router.parseUrl(
            this.router.url,
        ).root.children.primary.segments[1].path;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        this.closeNavigationMenu();

        this.loginService.updateAccountList().then((value: any) => {
            this.accountMenu = value;
            const curruentAccData: { id: string; name: any }[] = [];
            value.forEach((element: { id: string; name: any }) => {
                if (this.currentAccount === element.id) {
                    this.accountName = element.name;
                    curruentAccData.push(element);
                }
            });
            this.setCurrentPressroom(curruentAccData);
            this.emailListener = `${environment.email}+${this.pressroomId}${environment.emailDomain}`;
        });
        if (this.pressroomId === 'noPressroom') {
            this.loginService
                .fetchUserInfo(
                    false,
                    this.pressroomId || '',
                    this.currentAccount || '',
                )
                .then((result) => {
                    this.runConfig();
                });
        } else {
            this.loginService
                .fetchUserInfo(
                    true,
                    this.pressroomId || '',
                    this.currentAccount || '',
                )
                .then((result) => {
                    this.runConfig();
                });
        }
        this.filteredNavigationItems = this.filterNavigationItemsForUser(
            this.allNavigationItems,
        );
    }

    private setupIcon(icon: AssetIcon) {
        this.iconRegistry.addSvgIcon(
            icon.name,
            this.sanitizer.bypassSecurityTrustResourceUrl(icon.path),
        );
    }

    private filterNavigationItemsForUser(navigationItems: NavigationItem[]) {
        return navigationItems
            .filter((navigationItem) => {
                if (
                    navigationItem.requiredRoles &&
                    navigationItem.requiredRoles.length > 0
                ) {
                    return navigationItem.requiredRoles.some((role) =>
                        this.currentUserRoles.includes(role),
                    );
                }
                return true;
            })
            .map((navigationItem) => {
                if (
                    navigationItem.children &&
                    navigationItem.children.length > 0
                ) {
                    navigationItem.children = this.filterNavigationItemsForUser(
                        navigationItem.children,
                    );
                }
                return navigationItem;
            });
    }

    changePassword() {
        this.route.navigateByUrl('/settings/user-account');
    }

    copyEmail(email: any) {
        navigator.clipboard
            .writeText(email)
            .then(() => {
                console.log('Email copied to clipboard:', email);
            })
            .catch((err) => {
                console.error('Could not copy email: ', err);
            });
    }

    goToLiveSite() {
        window.open('/pressroom/' + this.pressroomId + '/home');
    }

    runConfig() {
        this.isNewUser = this.auth.isPasswordUpdated();
        if (!this.isNewUser) {
            this.allNavigationItems.forEach((navigation: any) => {
                navigation.isDisabled = true;
                if (navigation?.children)
                    navigation.children.forEach((children: any) => {
                        children.isDisabled = true;
                    });
            });

            this.allNavigationItems.push({
                routerLink: '/settings/user-account',
                title: 'Account',
                svgIcon: 'manage_accounts',
                isDisabled: false,
                viewExpanded: false,
                requiredRoles: [],
                children: [],
            });
            this.route.navigateByUrl('/settings/user-account');
        }

        this.route.events
            .pipe(
                filter(
                    (event): event is NavigationEnd =>
                        event instanceof NavigationEnd,
                ),
                startWith(this.route),
            )
            .subscribe((event: any) => {
                const url = event.url;
                if (!url.includes('/login')) {
                    this.currentRoute =
                        this.filteredNavigationItems.find(
                            (navigationItem: NavigationItem) =>
                                url.includes(navigationItem.routerLink),
                        )?.title || 'Pressroom';
                }
            });
        this.isLoading = false;
    }

    toggleNav() {
        this.isMenuOpen = !this.isMenuOpen;
        if (this.isMenuOpen) {
            this.contentMargin = 265;
        } else {
            this.contentMargin = 56;
        }
    }

    openNavigationMenu() {
        this.isNavigationMenuOpen = true;
        this.navigationMenuWidth = 265;
    }

    closeNavigationMenu() {
        this.isNavigationMenuOpen = false;
        this.navigationMenuWidth = 56;
    }

    logout() {
        this.loginService.logout();
    }

    navigate(dest: any) {
        this.route.navigateByUrl(`${dest}`);
    }

    setCurrentPressroom(value: string | any[]) {
        if (this.pressroomId?.toString() === 'noPressroom') {
            if (value.length > 0) {
                if (value[0].pressrooms.length > 0) {
                    this.pressroomId = value[0].pressrooms[0].id;
                    this.localStorageService.setItem(
                        ACCESS_KEYS.CURRENT_PRESSROOM,
                        value[0].pressrooms[0].id,
                    );
                } else {
                    this.localStorageService.setItem(
                        ACCESS_KEYS.CURRENT_PRESSROOM,
                        'noPressroom',
                    );
                }
            } else {
                this.localStorageService.setItem(
                    ACCESS_KEYS.CURRENT_PRESSROOM,
                    'noPressroom',
                );
            }
        }
    }

    updatePressroom(accountId: string, pressroomId: string) {
        if (accountId === this.currentAccount) {
            if (pressroomId === this.pressroomId) {
                console.log('Same Account and pressroom');
            } else {
                console.log('Same Account different pressroom');
                this.localStorageService.setItem(
                    ACCESS_KEYS.CURRENT_PRESSROOM,
                    pressroomId,
                );
                this.pressroomId = pressroomId;
                window.location.reload();
            }
        } else {
            this.getUpdatedToken(accountId).then((result) => {
                this.localStorageService.setItem(
                    ACCESS_KEYS.CURRENT_PRESSROOM,
                    pressroomId,
                );
                this.localStorageService.setItem(
                    ACCESS_KEYS.CURRENT_ACCOUNT,
                    accountId,
                );
                this.pressroomId = pressroomId;
                window.location.reload();
            });
        }
    }

    // POST resetToken
    getUpdatedToken(accountId: any) {
        return new Promise((resolve, reject) => {
            let body = {
                accountId,
            };
            this.http
                .post(`${environment.url}api/v1/identity/resetToken`, body)
                .subscribe(
                    (result: any) => {
                        this.localStorageService.setItem(
                            ACCESS_KEYS.AUTH_TOKEN,
                            result.token,
                        );
                        this.appService.setAuthToken(result.token);
                        resolve(true);
                    },
                    (error: any) => {
                        console.error('Something Wrong with new token!');
                        reject(error);
                    },
                );
        });
    }
}
