<div *ngIf="isLoading">
    <div class="loaderMain">
        <div class="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div>
<router-outlet *ngIf="!isLoading"></router-outlet>
