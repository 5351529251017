import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root',
})
export class SeoService {
    constructor(
        private meta: Meta,
        private titleService: Title,
    ) {}

    updateMetaInfo({
        title,
        description,
        imageUrl,
        date,
    }: {
        title: string;
        description: string;
        imageUrl: string | null | any;
        date: string;
    }) {
        this.titleService.setTitle(title);
        const filterDescription = description
            ? description.replace(/(<([^>]+)>)/gi, '') || ''
            : '';

        this.meta.updateTag({
            property: 'og:type',
            content: 'website' || filterDescription,
        });

        this.meta.updateTag({
            property: 'og:site_name',
            content: 'Pressroom',
        });

        this.meta.updateTag({ name: 'description', content: description });
        this.meta.updateTag({ property: 'og:title', content: title });
        this.meta.updateTag({
            property: 'og:description',
            content: filterDescription,
        });
        this.meta.updateTag({ property: 'og:image', content: imageUrl });
        this.meta.updateTag({
            property: 'og:image:width',
            content: '1200',
        });
        this.meta.updateTag({
            property: 'og:image:height',
            content: '627',
        });
        this.meta.updateTag({ property: 'og:type', content: 'website' });
        this.meta.updateTag({ property: 'og:updated_time', content: date });

        // Twitter cards
        this.meta.updateTag({
            name: 'twitter:card',
            content: 'summary_large_image',
        });
        this.meta.updateTag({ name: 'twitter:title', content: title });
        this.meta.updateTag({
            name: 'twitter:description',
            content: filterDescription,
        });
        this.meta.updateTag({
            name: 'twitter:site',
            content: filterDescription,
        });
        this.meta.updateTag({ name: 'twitter:image', content: imageUrl });
    }
}
