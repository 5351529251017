import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RouterModule } from '@angular/router';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ArticleCardOneComponent } from './cards/article-card-one/article-card-one.component';
import { StorySkeletonComponent } from './skeleton/story/story-skeleton.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ArticleCardsSkeletonComponent } from './skeleton/article-cards-skeleton/article-cards-skeleton.component';
import { ArticleCardTwoComponent } from './cards/article-card-two/article-card-two.component';
import { ArticleCardThreeComponent } from './cards/article-card-three/article-card-three.component';
import { AlbumsComponent } from './albums/albums.component';
import { LazyLoadDirective } from '../shared/common/lazy-load.directive';
import { SanitizeHtmlModule } from '../safe.pipe.module';
import { CategoryChipComponent } from './category-chip/category-chip.component';

@NgModule({
    declarations: [
        ConfirmDialogComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        ArticleCardOneComponent,
        StorySkeletonComponent,
        ArticleCardsSkeletonComponent,
        ArticleCardTwoComponent,
        ArticleCardThreeComponent,
        AlbumsComponent,
        LazyLoadDirective,
        CategoryChipComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        MaterialModule,
        NgxSkeletonLoaderModule,
        SanitizeHtmlModule,
    ],
    exports: [
        ConfirmDialogComponent,
        ForgotPasswordComponent,
        ArticleCardOneComponent,
        StorySkeletonComponent,
        ArticleCardsSkeletonComponent,
        ArticleCardTwoComponent,
        ArticleCardThreeComponent,
        AlbumsComponent,
        NgxSkeletonLoaderModule,
        LazyLoadDirective,
        CategoryChipComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {}
