import {
    Component,
    OnInit,
    ViewChild,
    HostListener,
    ElementRef,
    ViewEncapsulation,
    Renderer2,
    AfterViewInit,
    NgZone,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { WebPageConfigService } from '../dashboard/website-config/website-config.service';
import {
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
} from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Font } from 'ngx-font-picker';
import { ArticleQueryParams, NewsService } from './news/news.service';
import { fromEvent, throttleTime } from 'rxjs';
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { SubscribeComponent } from './subscribe/subscribe.component';

export interface Content {
    title?: string;
    date?: string;
    author?: string;
    image_URL?: string;
    createdOn?: string;
    updatedOn?: string;
    main_Body?: string;
    isVisible?: boolean;
}

@Component({
    selector: 'app-site',
    templateUrl: './site.component.html',
    styleUrl: './site.component.scss',
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('fadeInOut', [
            state('void', style({ opacity: 0 })),
            transition('void <=> *', [animate(200)]),
        ]),
    ],
})
export class SiteComponent implements OnInit, AfterViewInit {
    defaultSearchImage = 'https://www.blumepress.com/img/blumepress-logo.webp';
    facebookLink = 'https://www.facebook.com/';
    instagramLink = 'https://www.instagram.com/';
    twitterLink = 'https://www.x.com/';
    youtubeLink = 'https://www.youtube.com/';
    linkedinLink = 'https://www.linkedin.com/';
    isLoading: Boolean = true;
    searchBarVisible: boolean = false;
    uid!: any;
    applyFont: any;
    iconSet = 1;
    isHeaderText: boolean;
    menuFlag!: boolean;
    navTextColor!: string;
    navButtonBgColor!: string;
    font: Font | any;
    headerFont: Font | any;
    aboutFont: Font | any;
    contactPersonFont: Font | any;
    screenHeight!: any;
    screenWidth!: any;
    scrolling!: boolean;
    contactTitle: String = 'Contact Person';
    contactName!: any;
    contactDesignation!: any;
    contactPhone!: any;
    contactEmail!: any;
    contactImage!: any;
    contactImageTooltipAltText!: any;
    headerLogo!: any;
    typeTextColor!: any;
    aboutfooterHeight!: any;
    hidePoweredBy!: any;
    aboutfooterBGColor!: any;
    aboutfooterFontColor!: any;
    separatorStyle!: any;
    navItems = {
        navItem1Name: 'Home',
        navItem2Name: 'News',
        navItem3Name: 'Media Albums',
        navItem4Name: '',
        navItem5Name: '',
        navItem6Name: '',
        navItem4Href: '',
        navItem5Href: '',
        navItem6Href: '',
    };
    hideGallary = true;
    headerLogoUrl = '#';
    headerLogoHeight = 70;
    contactLists!: any;
    contactPersons: any = [];
    isAllHiddenContact = false;
    contactfooterHeight!: any;
    contactfooterBGColor!: any;
    contactfooterFontColor!: any;
    primaryBgcolor = '#131313';
    primaryFontcolor = '#ffffff';
    hideBackToTop = false;
    contentTitle: any = '';
    favIconUrl: HTMLLinkElement | any = document.querySelector('#favIcon');

    isArticleFetching = false;
    isSticky: Boolean = true;

    aboutUs: any;

    hideOps = {
        header: false,
        aboutUs: false,
        contactPerson: false,
        contactList: false,
    };
    logoBgColor!: any;
    buttonsConfigs!: any;

    debouncedApplyFilter!: (event: any) => void;

    @ViewChild('clickMenuTrigger', { static: false })
    clickMenuTrigger!: MatMenuTrigger;
    rssLink: any;
    customFooterDescription =
        "Ready to take your digital presence to the next level? We'd love to hear from you! Whether you're interested in our services, have questions, or simply want to say hello, our team is here to help.";
    printArticle: any;
    filteredArticles: any = [];
    isUnsticky: Boolean = false;
    constructor(
        private webService: WebPageConfigService,
        public newsService: NewsService,
        private router: Router,
        private el: ElementRef,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private renderer: Renderer2,
        private ngZone: NgZone,
        public dialog: MatDialog,
    ) {
        this.debouncedApplyFilter = this.debounce(
            this.applyFilter.bind(this),
            300,
        );

        iconRegistry.addSvgIcon(
            'custom-call',
            sanitizer.bypassSecurityTrustResourceUrl('assets/icons/call.svg'),
        );
        iconRegistry.addSvgIcon(
            'custom-email',
            sanitizer.bypassSecurityTrustResourceUrl('assets/icons/email.svg'),
        );
        iconRegistry.addSvgIcon(
            'blumepress',
            sanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/blumepress.svg',
            ),
        );
        this.loadIconSet();

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.isLoading = true;
            }
            if (
                event instanceof NavigationEnd ||
                event instanceof NavigationCancel ||
                event instanceof NavigationError
            ) {
                this.isLoading = false;
            }
        });
        this.isHeaderText = false;
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?: any) {
        // Get Window Size for hamburger menu
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
        if (this.screenWidth >= 1270) {
            if (this.clickMenuTrigger) {
                this.clickMenuTrigger.closeMenu();
            }
            this.menuFlag = true;
            this.searchBarVisible = false;
        } else {
            this.menuFlag = false;
        }
    }

    ngAfterViewInit() {
        this.ngZone.runOutsideAngular(() => {
            fromEvent(window, 'scroll')
                .pipe(throttleTime(50))
                .subscribe(() => {
                    requestAnimationFrame(() => this.handleScroll());
                });
        });
    }

    handleScroll() {
        const currentPosition = window.scrollY;

        let newSticky = true;
        let newUnsticky = false;

        if (currentPosition > 0 && currentPosition < 450) {
            newSticky = true;
        } else if (currentPosition >= 450) {
            newUnsticky = true;
        } else {
            newUnsticky = false;
        }

        // Only run change detection if the state actually changes
        if (this.isSticky !== newSticky || this.isUnsticky !== newUnsticky) {
            this.ngZone.run(() => {
                this.isSticky = newSticky;
                this.isUnsticky = newUnsticky;
            });
        }
    }

    ngOnInit() {
        this.getScreenSize();
        this.uid = this.router.parseUrl(
            this.router.url,
        ).root.children.primary.segments[1].path;
        if (this.uid.length > 0) {
            this.loadSite();
        }
    }

    loadArticles(searchText: any) {
        const articleQueryParams: ArticleQueryParams = {
            page: 1,
            itemsPerPage: 5,
            searchText: searchText,
            isVisible: true,
        };
        this.newsService.getArticles(articleQueryParams).subscribe((val) => {
            window.scrollTo({
                top: 0,
                left: 100,
                behavior: 'smooth',
            });
            this.printArticle = val.response;
            this.filteredArticles = this.printArticle;
            this.isArticleFetching = false;
        });
    }
    loadIconSet() {
        const socialMediaIcons = [
            'facebook',
            'instagram',
            'x',
            'youtube',
            'linkedin',
            'rss',
            'return',
        ];
        const packs = ['one', 'two', 'three', 'four'];

        packs.forEach((pack, packIndex) => {
            socialMediaIcons.forEach((icon) => {
                const iconName = `${packIndex + 1}_${icon}`;
                const iconUrl = `assets/webConf/social-packs/pack-${pack}/${iconName}.svg`;
                this.iconRegistry.addSvgIcon(
                    iconName,
                    this.sanitizer.bypassSecurityTrustResourceUrl(iconUrl),
                );
            });
        });
    }

    loadGoogleTagScript(config: any): void {
        try {
            const gtagScript = this.renderer.createElement('script');
            gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${config}`;
            gtagScript.async = true;
            this.renderer.appendChild(document.head, gtagScript);

            // Create the second script element for the Google Analytics configuration
            const inlineScript = this.renderer.createElement('script');
            inlineScript.text = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${config}');
            `;
            this.renderer.appendChild(document.head, inlineScript);
        } catch (error) {
            console.warn('Fail to load google tag script');
        }
    }
    delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

    loadSite() {
        this.webService
            .getConfig(this.uid)
            .then((value: any) => {
                const {
                    footer: { aboutUs, contactPerson, contactPersons },
                    theme,
                    header,
                    images,
                    buttonSectionConfig,
                    body,
                } = value;

                this.hideOps.aboutUs = aboutUs.hide;
                this.typeTextColor = theme.secondaryBgColor;

                if (header.headerLogoUrl?.length > 0) {
                    this.headerLogoUrl = header.headerLogoUrl;
                    try {
                        const logoURLDomain = new URL(this.headerLogoUrl);
                        this.favIconUrl.href = `https://icons.duckduckgo.com/ip3/${logoURLDomain.hostname}.ico`;
                    } catch (error) {
                        console.warn('Can not set dynamic favicon');
                    }
                }

                if (this.valid(body?.bodyContentTitle)) {
                    this.contentTitle = body.bodyContentTitle;
                }

                if (contactPerson) {
                    this.hideOps.contactPerson = contactPerson.hide;
                }

                if (contactPersons) {
                    this.hideOps.contactList = contactPersons.hide;
                    this.contactLists =
                        contactPersons.contactPersonList.length > 0;
                }

                if (buttonSectionConfig) {
                    this.separatorStyle =
                        buttonSectionConfig.separatorStyle || 1;
                    this.buttonsConfigs = buttonSectionConfig;
                }

                this.hideOps.header = header.hide;

                if (header.navButtonBgColor) {
                    this.navButtonBgColor =
                        header.navButtonBgColor || '#ffffff';
                }

                if (header.headerLogoHeight) {
                    this.headerLogoHeight = header.headerLogoHeight;
                }

                this.font = new Font(theme.font);
                const liveSiteContent =
                    document.getElementById('liveSiteContent');
                if (liveSiteContent) {
                    liveSiteContent.style.opacity = '100';
                    liveSiteContent.style.backgroundColor =
                        theme.primaryBgColor;
                }

                // Default theme settings
                this.aboutfooterBGColor = theme.secondaryBgColor;
                this.aboutfooterFontColor = theme.fontColor;
                this.contactfooterBGColor = theme.primaryBgColor;
                this.contactfooterFontColor = theme.fontColor;
                this.navTextColor = theme.secondaryBgColor;
                this.primaryBgcolor = theme.primaryBgColor;
                this.primaryFontcolor = theme.fontColor;
                this.hideBackToTop = theme.hideBackToTop;

                const googleAnalyticsTag = theme.googleAnalyticsTag ?? '';
                if (googleAnalyticsTag && googleAnalyticsTag.length > 10) {
                    this.loadGoogleTagScript(googleAnalyticsTag);
                }
                if (!this.hideOps.header) {
                    const liveSiteHeader =
                        document.getElementById('liveSiteHeader');
                    if (liveSiteHeader) {
                        liveSiteHeader.style.backgroundColor =
                            theme.primaryBgColor;
                    }

                    if (!this.isHeaderText) {
                        const liveSiteHeaderTitle = document.getElementById(
                            'liveSiteHeaderTitle',
                        );
                        if (liveSiteHeaderTitle)
                            liveSiteHeaderTitle.style.color = theme.fontColor;
                    }
                }

                // ABOUT US
                this.aboutUs = aboutUs;

                this.aboutFont = new Font(aboutUs.aboutFont || theme.font);
                this.hidePoweredBy = aboutUs.hidePoweredBy;

                if (aboutUs.override) {
                    this.aboutfooterBGColor = aboutUs.aboutBgColor;
                    this.aboutfooterFontColor = aboutUs.aboutFontColor;
                }

                this.aboutfooterHeight = aboutUs.aboutHeight;

                // Social ICONS
                this.twitterLink = aboutUs.twitter;
                this.facebookLink = aboutUs.facebook;
                this.youtubeLink = aboutUs.youtube;
                this.instagramLink = aboutUs.instagram;
                this.linkedinLink = aboutUs.linkedin;
                this.rssLink = aboutUs.rss || '';
                this.iconSet = aboutUs.iconSet;

                if (this.contactLists) {
                    const {
                        contactTitle,
                        contactPersonList,
                        contactPersonFont,
                        override,
                        contactBgColor,
                        contactFontColor,
                        contactHeight,
                    } = contactPersons;

                    this.contactTitle = contactTitle;
                    this.contactPersons = contactPersonList;
                    if (this.contactPersons) {
                        this.isAllHiddenContact = this.contactPersons.every(
                            (obj: any) => obj.hide === true,
                        );
                    }
                    this.contactPersonFont = new Font(
                        contactPersonFont || theme.font,
                    );

                    if (override) {
                        this.contactfooterBGColor = contactBgColor;
                        this.contactfooterFontColor = contactFontColor;
                        this.contactfooterHeight = contactHeight;
                    }
                    this.hideOps.contactPerson = true;
                } else {
                    const {
                        contactTitle,
                        contactPersonName,
                        contactPersonDesignation,
                        contactPersonPhone,
                        contactPersonEmail,
                        override,
                        contactBgColor,
                        contactFontColor,
                        contactHeight,
                    } = contactPerson;

                    this.hideOps.contactList = true;
                    this.contactTitle = contactTitle;
                    this.contactName = contactPersonName;
                    this.contactDesignation = contactPersonDesignation;
                    this.contactPhone = contactPersonPhone;
                    this.contactEmail = contactPersonEmail;
                    this.contactImage = images.contactImage;
                    this.contactImageTooltipAltText =
                        images.contactImageTooltipAltText;

                    if (override) {
                        this.contactfooterBGColor = contactBgColor;
                        this.contactfooterFontColor = contactFontColor;
                        this.contactfooterHeight = contactHeight;
                    }
                    this.contactPersonFont = new Font(
                        contactPersons?.contactPersonFont || theme.font,
                    );
                }

                // HEADER
                this.isHeaderText = header.isHeaderText;

                if (!this.hideOps.header) {
                    const liveSiteHeader =
                        document.getElementById('liveSiteHeader');
                    const siteToolbarMain =
                        document.getElementById('siteToolbarMain');

                    if (liveSiteHeader) {
                        if (header.override) {
                            liveSiteHeader.style.backgroundColor =
                                header.headerBgcolor;
                            this.logoBgColor = header.headerBgcolor;
                        }
                        liveSiteHeader.style.height = header.headerHeight;
                    } else {
                        console.warn(
                            'Element with ID liveSiteHeader not found.',
                        );
                    }
                    if (siteToolbarMain) {
                        siteToolbarMain.style.height = header.headerHeight;
                    }
                }

                // FONT Configure
                this.headerFont = new Font(header.headerFont || theme.font);
                this.applyFont = this.font.family.replace(' ', '+');

                if (this.menuFlag && header.override) {
                    this.navTextColor = header.headerFontColor;
                }

                //Nav Items
                this.navItems = header;
                this.hideGallary = header.hideGallary;

                if (!this.menuFlag && header.override) {
                    this.navTextColor = header.headerFontColor;
                }

                if (this.isHeaderText && !this.hideOps.header) {
                    const oImg = new Image();
                    oImg.src = images.logoImage;
                    oImg.alt = images.logoImageTooltipAltText;
                    oImg.title = images.logoImageTooltipAltText;
                    oImg.height = this.headerLogoHeight;
                    document.getElementById('headerLogo')?.appendChild(oImg);

                    const fImg = new Image();
                    fImg.src = images.logoImage;
                    fImg.alt = images.logoImageTooltipAltText;
                    fImg.height = this.headerLogoHeight;
                    document.getElementById('footerLogo')?.appendChild(fImg);

                    this.headerLogo = images.logoImage;

                    const headerLogo = document.getElementById('headerLogo');
                    const footerLogo = document.getElementById('footerLogo');

                    if (headerLogo) {
                        headerLogo.title = images.logoImageTooltipAltText;
                    } else {
                        console.warn('Element with ID headerLogo not found.');
                    }

                    if (footerLogo) {
                        footerLogo.title = images.logoImageTooltipAltText;
                    } else {
                        console.warn('Element with ID footerLogo not found.');
                    }

                    document
                        .getElementById('headerLogo')
                        ?.setAttribute('alt', images.logoImageTooltipAltText);
                    document
                        .getElementById('footerLogo')
                        ?.setAttribute('alt', images.logoImageTooltipAltText);

                    const liveSiteHeaderTitle = document.getElementById(
                        'liveSiteHeaderTitle',
                    );
                    const liveSiteFooterTitle = document.getElementById(
                        'liveSiteFooterTitle',
                    );

                    if (liveSiteHeaderTitle) {
                        liveSiteHeaderTitle.style.display = 'none';
                    } else {
                        console.warn(
                            'Element with ID liveSiteHeaderTitle not found.',
                        );
                    }

                    if (liveSiteFooterTitle) {
                        liveSiteFooterTitle.style.display = 'none';
                    } else {
                        console.warn(
                            'Element with ID liveSiteFooterTitle not found.',
                        );
                    }

                    document.getElementById('liveSiteHeaderTitle')?.remove();
                    document.getElementById('liveSiteFooterTitle')?.remove();
                } else if (!this.hideOps.header) {
                    const liveSiteHeaderTitle = document.getElementById(
                        'liveSiteHeaderTitle',
                    );

                    if (liveSiteHeaderTitle) {
                        liveSiteHeaderTitle.textContent = header.headerText;
                        liveSiteHeaderTitle.style.fontSize =
                            header.headerTitleFontSize;
                        if (header.override) {
                            liveSiteHeaderTitle.style.color =
                                header.headerFontColor;
                        }
                    } else {
                        console.warn(
                            'Element with ID liveSiteHeaderTitle not found.',
                        );
                    }

                    document.getElementById('headerLogo')?.remove();

                    const liveSiteFooterTitle = document.getElementById(
                        'liveSiteFooterTitle',
                    );

                    if (liveSiteFooterTitle) {
                        liveSiteFooterTitle.textContent = header.headerText;
                        liveSiteFooterTitle.style.fontSize =
                            header.headerTitleFontSize;
                        if (header.override) {
                            liveSiteFooterTitle.style.color =
                                header.headerFontColor;
                        }
                    } else {
                        console.warn(
                            'Element with ID liveSiteFooterTitle not found.',
                        );
                    }

                    document.getElementById('headerLogo')?.remove();
                }
            })
            .catch(async (err) => {
                console.error(err);
                await this.delay(2000);
                window.location.href = 'https://www.blumepress.com/';
            })
            .finally(() => {
                this.isLoading = false;
            });
    }
    subscribePressroom() {
        const dialog = this.dialog.open<SubscribeComponent>(
            SubscribeComponent,
            {
                maxWidth: '600px',
                maxHeight: '80vh',
                data: {
                    logo: this.headerLogo,
                    config: this.buttonsConfigs,
                    logoBgColor: this.logoBgColor,
                },
            },
        );
        dialog.afterClosed().subscribe((isConfirmed) => {
            if (isConfirmed) {
                //Store in coockies
            }
        });
    }

    facebook() {
        window.open(this.facebookLink);
    }

    youtube() {
        window.open(this.youtubeLink);
    }

    instagram() {
        window.open(this.instagramLink);
    }

    twitter() {
        window.open(this.twitterLink);
    }
    linkedin() {
        window.open(this.linkedinLink);
    }
    rss() {
        window.open(this.rssLink);
    }

    debounce(func: Function, wait: number) {
        let timeout: number | undefined;
        return (...args: any[]) => {
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = window.setTimeout(() => {
                func.apply(this, args);
            }, wait);
        };
    }

    applyFilter(event: any) {
        const filterValue = event.target.value;
        if (filterValue.length > 1) {
            this.isArticleFetching = true;
            this.loadArticles(filterValue.toLowerCase());
        } else {
            this.isArticleFetching = false;
            this.filteredArticles = [];
        }
    }
    onSearchBoxInput(event: any) {
        this.debouncedApplyFilter(event);
    }

    htmlToPlaintext(text: any) {
        try {
            const doc = new DOMParser().parseFromString(text, 'text/html');
            text = doc.body.textContent || '';
        } catch (ex) {}
        text = text.replace(/\s\s+/g, ' ').replace(/<br\s*[\/]?>/gi, ' ');
        return text;
    }
    openArticle(item: any) {
        this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() =>
                this.router.navigate([
                    '/pressroom/' + item.pressroomId + '/article/' + item._id,
                ]),
            );
    }
    navigatePage(page: any) {
        const path = `/pressroom/${this.uid}/${page}`;
        this.router.navigateByUrl(path);
    }

    navigatePageCustom(link: any) {
        window.open(link, '_blank');
    }

    aboutfooterConfig(): object {
        let configure = {
            height: this.aboutfooterHeight,
            'background-color': this.aboutfooterBGColor,
            color: this.aboutfooterFontColor,
        };
        if (this.aboutFont) {
            let fontConfig = this.aboutFont.getStyles();
            configure = {
                ...configure,
                ...fontConfig,
            };
        }
        return configure;
    }
    contactfooterConfig(): object {
        let configure = {
            height: this.contactfooterHeight,
            'background-color': this.contactfooterBGColor,
            color: this.contactfooterFontColor,
        };
        if (this.contactPersonFont) {
            let fontConfig = this.contactPersonFont.getStyles();
            configure = {
                ...configure,
                ...fontConfig,
            };
        }
        return configure;
    }
    contactPersonConfig(contact: any): object {
        const configure = {
            'background-color': contact.contactBgColor,
            color: contact.contactFontColor,
        };
        return configure;
    }
    valid(str: any) {
        if (str === '' || str === null || str === undefined) return false;
        else return true;
    }

    goToSite() {
        const pattern = /^((http|https|ftp):\/\/)/;
        if (!pattern.test(this.headerLogoUrl)) {
            this.headerLogoUrl = 'http://' + this.headerLogoUrl;
        }
        window.open(this.headerLogoUrl);
    }

    toggleSearch() {
        this.searchBarVisible = !this.searchBarVisible;
        this.filteredArticles = [];
    }

    clearFilter() {
        this.filteredArticles = [];
    }

    backToTop() {
        window.scrollTo({
            top: 0,
            left: 100,
            behavior: 'smooth',
        });
    }
}
