import { Inject, Injectable } from '@angular/core';
import { WINDOW_INJECTION_TOKEN } from '../tokens/window.injection.token';

export enum ACCESS_KEYS {
    AUTH_TOKEN = 'authToken',
    BLUMEPRESS_ID = 'blumepressId',
    CURRENT_PRESSROOM = 'Active-Pressroom',
    CURRENT_ACCOUNT = 'Active-Account',
    WEB_TAB = 'pressConfigTabLocation',
}

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    constructor(
        @Inject(WINDOW_INJECTION_TOKEN) private readonly window: Window,
    ) {}

    get ACCESS_KEYS(): typeof ACCESS_KEYS {
        return ACCESS_KEYS;
    }

    getItem(key: ACCESS_KEYS): string {
        return this.window.localStorage.getItem(key) || '';
    }

    setItem(key: ACCESS_KEYS, value: string): void {
        this.window.localStorage.setItem(key, value);
    }

    removeItem(key: ACCESS_KEYS): void {
        this.window.localStorage.removeItem(key);
    }
}
