import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SubscribeService } from './subscribe.service';
import { CookieService } from 'src/app/cookie.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-subscribe',
    templateUrl: './subscribe.component.html',
    styleUrl: './subscribe.component.scss',
})
export class SubscribeComponent implements OnInit {
    subscriberForm!: FormGroup;
    recaptchaToken: string | null = null;
    isSubscribed = false;
    isError = false;
    alreadySubscriberEmail = '';
    alreadySubscriberUserId = '';
    buttonsConfigs!: any;
    isLoading = false;
    pressroomId = '';
    isUnsubscribed = false;
    isOptedIn = false;
    isFetchingData = true;

    constructor(
        private fb: FormBuilder,
        private subscribeService: SubscribeService,
        private cookieService: CookieService,
        private translate: TranslateService,
        public route: Router,
        private dialogRef: MatDialogRef<SubscribeComponent, boolean>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        const supportedLanguages = [
            'en',
            'da',
            'de',
            'es',
            'fi',
            'fr',
            'ja',
            'nb',
            'nl',
            'nn',
            'pt',
            'sv',
            'zh',
        ];

        // Detect the browser language
        const browserLang = translate.getBrowserLang() ?? 'en';

        // Set the default language to English if the browser language isn't supported
        translate.setDefaultLang('en');

        // Use the detected language if it's supported
        if (supportedLanguages.includes(browserLang)) {
            translate.use(browserLang);
        } else {
            translate.use('en'); // Default to English
        }
    }

    ngOnInit(): void {
        this.pressroomId = this.route.parseUrl(
            this.route.url,
        ).root.children.primary.segments[1].path;
        this.buttonsConfigs = this.data?.config ?? {};
        this.alreadySubscriberUserId =
            this.cookieService.getCookie(`subscriber::${this.pressroomId}`) ??
            '';
        if (
            this.alreadySubscriberUserId &&
            this.alreadySubscriberUserId.length > 5
        ) {
            this.getSubscriberDetails(this.alreadySubscriberUserId);
        } else {
            this.isFetchingData = false;
        }

        this.subscriberForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            terms: [false, Validators.requiredTrue],
        });
    }

    resolved(captchaResponse: any): void {
        this.recaptchaToken = captchaResponse;
    }

    getSubscriberDetails(userId: any) {
        this.subscribeService
            .getSubscriber(this.pressroomId, userId)
            .pipe(
                catchError((error) => {
                    console.error('Fetching subscription failed:', error);
                    this.isError = true;
                    this.isLoading = false;
                    this.isFetchingData = false;
                    return of(null);
                }),
            )
            .subscribe((response: any) => {
                if (response && response.user) {
                    this.isSubscribed = true;
                    this.alreadySubscriberUserId = response.user._id ?? '';
                    this.alreadySubscriberEmail = response?.user?.email ?? '';
                    this.cookieService.setCookie(
                        `subscriber::${this.pressroomId}`,
                        `${this.alreadySubscriberUserId}`,
                    );
                    this.isUnsubscribed = response.user.isUnsubscribed;
                    this.isOptedIn = response.user.isOptIn;
                } else {
                    this.isError = true;
                }
                this.isLoading = false;
                this.isFetchingData = false;
            });
    }
    reSubscribe() {
        const cookieName = `subscriber::${this.pressroomId}` ?? '';
        const isExist = this.cookieService.getCookie(cookieName);
        if (isExist) {
            this.cookieService.deleteCookie(cookieName);
            this.alreadySubscriberEmail = '';
            this.alreadySubscriberUserId = '';
            this.isLoading = false;
            this.isFetchingData = false;
        }
    }

    onSubmit(): void {
        this.isLoading = true;
        this.isError = false; // Reset error state

        if (this.subscriberForm.valid && this.recaptchaToken) {
            const formData = {
                email: this.subscriberForm.value.email,
                recaptchaToken: this.recaptchaToken,
                termsAccepted: this.subscriberForm.value.terms,
            };

            this.subscribeService
                .submitSubscriber(formData)
                .pipe(
                    catchError((error) => {
                        console.error('Subscription failed:', error);
                        this.isError = true;
                        this.isLoading = false;
                        return of(null);
                    }),
                )
                .subscribe((response: any) => {
                    if (response && response.status && response.userId) {
                        this.cookieService.setCookie(
                            `subscriber::${this.pressroomId}`,
                            `${response.userId}`,
                        );
                        this.isSubscribed = true;
                        this.alreadySubscriberUserId = response.userId ?? '';
                        this.alreadySubscriberEmail = formData.email ?? '';
                    } else {
                        this.isError = true;
                    }
                    this.isLoading = false;
                });
        } else {
            this.isLoading = false; // Stop loading if form or recaptchaToken are invalid
        }
    }

    onNoClick() {
        this.dialogRef.close();
    }
}
