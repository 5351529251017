<div
    class="card-item"
    [ngStyle]="isCompact ? { 'padding-inline-start': '0px' } : {}"
>
    <div class="list-item" [ngClass]="ngClassStyle">
        <div
            class="card-box"
            [ngStyle]="{
                'background-color': config.cardArticlecolor,
                height: noImage
                    ? isCompact
                        ? '200px'
                        : '250px'
                    : !noImage && isCompact
                      ? '200px'
                      : '340px'
            }"
        >
            <div
                class="{{
                    isCompact ? 'text-margin-compact' : ''
                }} text-content-container"
                [ngStyle]="
                    noImage
                        ? {
                              width: '100%',
                              'margin-top': isCompact ? '0px' : '10px'
                          }
                        : !noImage && isCompact
                          ? { width: '80%' }
                          : {}
                "
            >
                <a class="text-content-link" (click)="openArticle(article)">
                    <div
                        class="text-wrapper"
                        [ngStyle]="isCompact ? { padding: '15px' } : {}"
                    >
                        <span
                            style="
                                font-size: small;
                                margin-bottom: 5px;
                                display: block;
                            "
                            *ngIf="contentCategory?.showOnCards"
                        >
                            <app-category-chip
                                [categoryId]="article?.categoryId"
                                [categories]="contentCategory.categories ?? []"
                                [bgColor]="contentCategory?.categoryBackground"
                                [textColor]="contentCategory?.categoryTextColor"
                            ></app-category-chip>
                        </span>
                        <div
                            class="published"
                            [ngStyle]="{
                                color: config.cardDatecolor
                            }"
                        >
                            {{
                                article.date
                                    | date
                                        : config.articleDateFormat
                                        : ''
                                        : config.articleDateLanguage
                            }}
                        </div>
                        <div
                            class="author"
                            *ngIf="!config.hideAuthor"
                            [ngStyle]="{
                                color: config.cardAuthorColor
                            }"
                        >
                            {{ article.author | slice: 0 : 25 }}
                        </div>
                        <h5
                            class="title line-limit-4"
                            [ngStyle]="{
                                color: config.cardTitlecolor
                            }"
                        >
                            {{
                                article.title | slice: 0 : (noImage ? 100 : 80)
                            }}
                            <span *ngIf="article?.title?.length > 80">...</span>
                        </h5>
                        <div
                            class="summary"
                            [ngStyle]="{ 'font-size': '13px' }"
                        >
                            <p
                                style="word-break: keep-all"
                                class="line-limit-5"
                                [ngStyle]="{
                                    color: config.cardIngresscolor
                                }"
                            >
                                {{
                                    htmlToPlaintext(
                                        article?.ingress || article.main_Body
                                    ) | slice: 0 : (noImage ? 240 : 180)
                                }}
                                <span
                                    *ngIf="
                                        article?.ingress?.length > 180 ||
                                        article?.main_Body?.length > 180
                                    "
                                >
                                    ...
                                </span>
                            </p>
                        </div>
                    </div>
                </a>
            </div>
            <div
                class="social-icons"
                [ngStyle]="{ color: config.typeTextColor }"
                *ngIf="!hideSocialIcons"
            >
                <a
                    target="_blank"
                    [ngStyle]="{
                        'text-decoration': 'none',
                        color: config.typeTextColor
                    }"
                    href="{{ shareFB(article) }}"
                    class="icon-facebook share-facebook"
                ></a>
                <a
                    target="_blank"
                    [ngStyle]="{
                        'text-decoration': 'none',
                        color: config.typeTextColor
                    }"
                    href="{{ shareTwitter(article) }}"
                    class="icon-x share-twitter"
                ></a>
                <script
                    async
                    src="https://platform.x.com/widgets.js"
                    charset="utf-8"
                ></script>
                <a
                    target="_blank"
                    [ngStyle]="{
                        'text-decoration': 'none',
                        color: config.typeTextColor
                    }"
                    href="{{ shareLinkedIn(article) }}"
                    class="icon-linkedin share-linkedIn"
                ></a>
            </div>
            <div
                class="image-container {{ isCompact ? 'isCompact' : '' }} {{
                    config.cardImageStyle
                }}"
                [ngStyle]="
                    noImage
                        ? { display: 'none' }
                        : !noImage && isCompact
                          ? {
                                width: '20%',
                                'font-size': '8px',
                                'background-color': 'whitesmoke'
                            }
                          : {}
                "
            >
                <a (click)="openArticle(article)">
                    <div
                        class="image-wrapper {{ config.cardImageStyle }}"
                        [ngStyle]="{
                            'background-image':
                                'url(' + article.image_URL + ')',
                            'background-size': 'cover',
                            'background-position': 'center'
                        }"
                    >
                        <div
                            *ngIf="noImage && !isCompact"
                            class="image-wrapper"
                            [ngStyle]="config.backgroundStyleDynamic"
                        >
                            <div
                                class="image-holder"
                                *ngIf="article.image_URL"
                                [ngStyle]="{
                                    'background-image':
                                        'url(' + article.image_URL + ')'
                                }"
                            ></div>
                        </div>
                    </div>
                    <div
                        class="image-holder-text"
                        *ngIf="article.image_URL === ''"
                    >
                        {{ article.title | slice: 0 : 1 }}
                    </div>
                </a>
            </div>
            <a
                (click)="openArticle(article)"
                class="read-more"
                [ngStyle]="{
                    'background-color': config.typeTextColor,
                    color: config.viewMoreColor
                }"
            >
                {{ config.viewMore }}
            </a>
        </div>
    </div>
</div>
