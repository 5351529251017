import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CookieService {
    constructor() {}

    // Set Cookie with a default of 3 months expiry
    setCookie(name: string, value: string, expiryMonths: number = 1): void {
        const now = new Date();
        // Calculate the expiry date in the specified number of months
        now.setMonth(now.getMonth() + expiryMonths);
        const expires = `expires=${now.toUTCString()}`;
        document.cookie = `${name}=${value};${expires};path=/`;
    }

    // Get Cookie by name
    getCookie(name: string): string | null {
        const nameEQ = `${name}=`;
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            // Trim leading spaces
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            // Return cookie value if name matches
            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    }

    // Delete Cookie by setting its expiry to a past date
    deleteCookie(name: string): void {
        const pastDate = new Date(0).toUTCString(); // Set the expiry date to the past
        document.cookie = `${name}=; expires=${pastDate}; path=/`;
    }
}
