import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-article-card-one',
    templateUrl: './article-card-one.component.html',
    styleUrl: './article-card-one.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleCardOneComponent {
    @Input() article: any;
    @Input() config: any;
    @Input() displayInternal: any;
    @Input() contentCategory: any;

    defaultCardImage = '../../../assets/webConf/press-release.jpg';
    constructor(private router: Router) {}

    htmlToPlaintext(text: any) {
        try {
            const doc = new DOMParser().parseFromString(text, 'text/html');
            text = doc.body.textContent || '';
        } catch (ex) {}
        text = text.replace(/\s\s+/g, ' ');
        return text.trim();
    }

    openArticle(item: any) {
        if (!this.article.isDummy)
            this.router.navigateByUrl(
                '/pressroom/' + item.pressroomId + '/article/' + item._id,
            );
    }
}
