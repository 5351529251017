<article
    *ngIf="article && config"
    [ngStyle]="displayInternal ? { container: 'inherit' } : {}"
>
    <div
        class="article-wrapper"
        [ngStyle]="{
            'background-color': config.cardArticlecolor,
            cursor: 'pointer'
        }"
        (click)="openArticle(article)"
    >
        <figure>
            <div
                class="image-container {{ config.cardImageStyle }}"
                [ngStyle]="{
                    'background-image': article.image_URL
                        ? 'url(' + article.image_URL + ')'
                        : 'url(' + config.defaultCardImage + ')',
                    'background-repeat': 'no-repeat',
                    'background-size': 'cover',
                    'background-position': 'center',
                    height: '100%',
                    width: '100%'
                }"
            >
                <!-- <div
                    class="image-wrapper {{ config.cardImageStyle }}"
                    [ngStyle]="config.backgroundStyleDynamic"
                    *ngIf="
                        article.image_URL && config.cardImageStyle !== 'cover'
                    "
                >
                    <div
                        class="image-holder"
                        [ngStyle]="{
                            'background-image':
                                'url(' + article.image_URL + ')',
                            'background-size': 'contain',
                            'background-position': 'center',
                            'background-repeat': 'no-repeat',
                            height: '100%',
                            width: '100%'
                        }"
                    ></div>
                </div> -->
            </div>
        </figure>
        <div
            class="article-body"
            [ngStyle]="{
                'background-color': config.cardArticlecolor
            }"
        >
            <span style="font-size: small" *ngIf="contentCategory?.showOnCards">
                <app-category-chip
                    [categoryId]="article?.categoryId"
                    [categories]="contentCategory.categories ?? []"
                    [bgColor]="contentCategory?.categoryBackground"
                    [textColor]="contentCategory?.categoryTextColor"
                ></app-category-chip>
            </span>

            <h3
                class="line-limit-2"
                style="padding-top: 0; margin-top: 5px"
                [ngStyle]="{
                    color: config.cardTitlecolor
                }"
            >
                {{ article.title | slice: 0 : 60 }}
                <span *ngIf="article?.title?.length > 60">...</span>
            </h3>
            <h5
                [ngStyle]="{
                    color: config.cardDatecolor,
                    margin: '5px 0px 0px 0px'
                }"
            >
                {{
                    article.date
                        | date
                            : config.articleDateFormat
                            : ''
                            : config.articleDateLanguage
                }}
            </h5>
            <h5
                *ngIf="!config.hideAuthor"
                [ngStyle]="{
                    margin: '5px 0px 0px 0px',
                    color: config.cardAuthorColor
                }"
            >
                {{ article.author | slice: 0 : 25 }}
            </h5>
            <p
                class="line-limit-2"
                [ngStyle]="{
                    color: config.cardIngresscolor
                }"
            >
                {{
                    htmlToPlaintext(article?.ingress || article.main_Body)
                        | slice: 0 : 180
                }}
                <span
                    *ngIf="
                        article?.ingress?.length > 180 ||
                        article?.main_Body?.length > 180
                    "
                >
                    ...
                </span>
            </p>

            <a
                class="read-more"
                [ngStyle]="{
                    color: config.viewMoreColor
                }"
            >
                {{ config.viewMore }}
                <mat-icon class="icon">chevron_right</mat-icon>
            </a>
        </div>
    </div>
</article>
