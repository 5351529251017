<div id="portfolio" style="border-radius: 1em">
    <div class="container" *ngIf="articlePortfolio">
        <div class="row">
            <div
                [ngStyle]="articlePortfolioFont?.getStyles()"
                style="margin-bottom: 10px"
            >
                <div
                    [innerHTML]="
                        articlePortfolio.portfolioBody | sanitizeHtml: 'html'
                    "
                ></div>
            </div>
        </div>
    </div>
</div>
