import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrl: './forgot-password.component.scss',
})
export class ForgotPasswordComponent implements OnInit {
    forgotPasswordForm!: FormGroup;
    spinner: boolean = false;
    constructor(
        private dialogRef: MatDialogRef<ForgotPasswordComponent>,
        public http: HttpClient,
        public snackBar: SnackbarService,
        private fb: FormBuilder,
    ) {}

    ngOnInit() {
        this.forgotPasswordForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
        });
    }

    async onSubmit() {
        this.spinner = true;
        const email = this.forgotPasswordForm.value.email;
        if (!email) {
            this.spinner = false;
            this.snackBar.showMessageWithDelay('Invalid Email!', 2000);
            return;
        }

        try {
            const result = await firstValueFrom(
                this.http.post(
                    `${environment.url}api/v1/identity/forget-password`,
                    { email },
                ),
            );
            this.snackBar.showMessageWithDelay(
                'If your account exists in our system, we have sent you a password reset email.',
                3000,
            );
            this.spinner = false;
            return result;
        } catch (err) {
            this.spinner = false;
            this.snackBar.showMessageWithDelay(
                'If your account exists in our system, we have sent you a password reset email.',
                3000,
            );
            throw err;
        } finally {
            this.dialogRef.close({
                email: this.forgotPasswordForm.value,
            });
        }
    }

    onCancel() {
        this.dialogRef.close('Cancel');
    }
}
