import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-albums',
    templateUrl: './albums.component.html',
    styleUrl: './albums.component.scss',
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlbumsComponent {
    @Input() galleryConfig: any;
    @Input() isInternal: any;
    @Input() isLimited: any;
    @Input() albumNameTextColor: any;
    @Output() imageRemoveItem = new EventEmitter<any>();

    selectedMedia: any;
    uid: any;

    constructor(private router: Router) {
        this.uid = this.router.parseUrl(
            this.router.url,
        ).root.children.primary.segments[1].path;
    }

    clearImageName(imageName: any) {
        let part1 = imageName.url
            ? imageName.url.indexOf('_')
            : imageName.indexOf('_');
        let finalImageName = imageName.url
            ? imageName.url.substring(part1 + '_'.length)
            : imageName.substring(part1 + '_'.length);
        let rest = imageName.url
            ? imageName.url.substring(0, part1)
            : imageName.substring(0, part1);
        return finalImageName;
    }
    isImage(url: string): boolean {
        return url.match(/\.(jpeg|jpg|gif|png|gif)$/) != null;
    }

    isVideo(url: string): boolean {
        return url.match(/\.(mp4|webm|ogg|mov|avi)$/) != null;
    }

    isPdf(url: string): boolean {
        return url.match(/\.pdf$/) != null;
    }

    navigatePage(page: any) {
        this.router.navigateByUrl(`/pressroom/${this.uid}/${page}`);
    }

    openMedia(item: any): void {
        if (this.isImage(item.url)) {
            this.selectedMedia = { ...item, type: 'image' };
        } else if (this.isPdf(item.url) || this.isVideo(item.url)) {
            this.selectedMedia = {
                type: this.isPdf(item.url) ? 'pdf' : 'video',
                url: item.url,
                tooltipAltText: item.tooltipAltText, // Optional: Pass alt text for PDFs as tooltip alt text
            };
        }
    }

    downloadMedia(url: string): void {
        const a: any = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        document.body.appendChild(a);
        a.download = this.clearImageName(url);
        a.click();
        document.body.removeChild(a);
    }

    onDeleteClick(mediaItem: any, albumName: any) {
        if (mediaItem && albumName) {
            this.imageRemoveItem.emit({ mediaItem, albumName });
        }
    }

    closeMedia(): void {
        this.selectedMedia = null;
    }
}
