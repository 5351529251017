<div class="site-body" id="site-body" [ngStyle]="font?.getStyles()">
    <div class="banner" id="banner" *ngIf="!hideBanner" @fadeInOut>
        <div class="overlay" id="bannerOverlay" *ngIf="bannerTextSlides"></div>
        <div class="slider-container" *ngIf="bannerTextSlides">
            <div class="carousel">
                <ul id="light-slider" style="padding: 0; margin: 0">
                    <li *ngFor="let slide of bannerTextSlides">
                        <div class="slide" [ngStyle]="{ height: bannerHeight }">
                            <h1
                                [ngStyle]="{
                                    'font-size': bannerHeaderTextSize
                                }"
                            >
                                {{ slide.mainText }}
                            </h1>
                            <h3
                                [ngStyle]="{
                                    'font-size': bannerSubHeaderTextSize
                                }"
                            >
                                {{ slide.subHeadline }}
                            </h3>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="content-container">
        <div class="container">
            <div class="gallery-header">
                <div class="header-container">
                    <div
                        *ngIf="!backButtonGallary && gallaryTitle"
                        class="container back-icon"
                    >
                        <mat-icon
                            class="iconItem"
                            svgIcon="{{ backButtonStyle }}_return"
                            (click)="returnBack()"
                        ></mat-icon>
                    </div>
                    <div
                        class="separator"
                        *ngIf="gallaryTitle !== ''"
                        [ngStyle]="{ 'background-color': typeTextColor }"
                    ></div>
                    <h1 *ngIf="gallaryTitle !== ''" @fadeInOut>
                        {{ gallaryTitle }}
                    </h1>
                </div>
            </div>
            <div class="row" *ngIf="galleryConfig" @fadeInOut>
                <app-albums [galleryConfig]="galleryConfig"></app-albums>
            </div>
            <div class="row" *ngIf="!galleryConfig" @fadeInOut>
                <ngx-skeleton-loader
                    *ngFor="let n of [].constructor(8); let i = index"
                    style="margin: 5px"
                    [theme]="{
                        width: '200px',
                        height: '200px',
                        'border-radius': '1.2rem'
                    }"
                ></ngx-skeleton-loader>
            </div>
        </div>
    </div>
    <img
        *ngIf="analyticsUrl"
        [appLazyLoad]="analyticsUrl"
        loading="lazy"
        class="analytics-image"
    />
    <div
        class="container"
        style="padding-bottom: 2em"
        *ngIf="articlePortfolio?.showOnGallery"
    >
        <div class="body-heading" [ngStyle]="font?.getStyles()">
            <span
                class="separator"
                [ngStyle]="{ 'background-color': typeTextColor }"
            ></span>
            <h1 *ngIf="articlePortfolio.portfolioSectionTitle !== ''">
                {{ articlePortfolio.portfolioSectionTitle }}
            </h1>
        </div>
        <app-article-portfolio
            [articlePortfolio]="articlePortfolio"
            [typeTextColor]="typeTextColor"
            [primaryBgColor]="primaryBgColor"
            [bodyFont]="font"
            style="color: initial"
        ></app-article-portfolio>
    </div>
</div>
