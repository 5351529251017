// News Page Component with all the article with a Single Card View
import { WebPageConfigService } from 'src/app/dashboard/website-config/website-config.service';
import { Component, OnInit } from '@angular/core';
import { ArticleQueryParams, NewsService } from './news.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { Font } from 'ngx-font-picker';
import { catchError, finalize, of } from 'rxjs';
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { environment } from 'src/environments/environment';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrl: './news.component.scss',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
    animations: [
        trigger('fadeInOut', [
            state('void', style({ opacity: 0 })),
            transition('void <=> *', [animate(500)]),
        ]),
    ],
})
export class NewsComponent implements OnInit {
    p = 1;
    maxDate = new Date();

    printArticle: any = [];
    isPrintArticleLoading = true;

    debouncedApplyFilter!: (event: any) => void;

    uid!: any;
    typeTextColor: any;
    secondaryBgColor: any;
    backButtonNews: any;
    articleDateColor: any;
    articleTitleColor: any;
    articleIngressColor: any;
    articleAuthorColor: any;
    articleDateFormat: any;
    newsHeaderTitle: any;
    readMore: any;
    hideDate = false;
    hideAuthor = false;
    hideImage = false;
    hideStartingText = false;
    hideReadMore = false;
    articleDateLanguage = 'en';
    isDateFiltered: boolean = false;
    readMoreColor!: string;
    newsArticleBgColor!: string;
    paginationTextColor: any;
    paginationNextLabel: any;
    paginationPreviousLabel: any;
    separatorStyle: any;
    font: Font | any;
    gallaryImages!: any;
    headerColor: any;
    showGalleryonNews: Boolean = false;
    itemsPerPage = 10;
    directionLinks = true;
    responsive: boolean = true;
    gallaryTitle: any;

    futureStories: any;
    isFutureStory = false;
    storySectionTitle: any;
    articleCardConfig: any;
    backButtonStyle = '1';

    primaryBgColor: any;
    articlePortfolio: any;
    searchTextInput: any = '';

    currentPage = 1;
    totalItems = 0;
    selectedMonth: any;
    selectedYear: any;
    analyticsUrl = '';

    enableFilters: any;
    monthTitleText: any;
    yearTitleText: any;
    contentCategory: any = [];
    selectedCategory: any = [''];
    selectedCategoryName: any;
    panelOpenState = false;
    isCategoryFilterApplied: boolean = false;

    constructor(
        public newsService: NewsService,
        public route: Router,
        private location: Location,
        public webService: WebPageConfigService,
        public dialog: MatDialog,
        private _adapter: DateAdapter<any>,
    ) {
        this.debouncedApplyFilter = this.debounce(
            this.applyFilter.bind(this),
            300,
        );
    }

    debounce(func: Function, wait: number) {
        let timeout: number | undefined;
        return (...args: any[]) => {
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = window.setTimeout(() => {
                func.apply(this, args);
            }, wait);
        };
    }
    isSelected(categoryId: string): boolean {
        return this.selectedCategory[0] === categoryId;
    }
    applyFilter(event: any) {
        const filterValue = event.target.value;
        if (filterValue.length >= 2) {
            this.filterChanged(filterValue.toLowerCase());
        } else if (filterValue.length === 0) {
            // Retrigger the filter when input is cleared
            this.filterChanged('');
        }
    }

    getMonths(locale: string): string[] {
        const formatter = new Intl.DateTimeFormat(locale, { month: 'long' });
        return Array.from({ length: 12 }, (_, i) =>
            formatter.format(new Date(2000, i, 1)),
        );
    }
    getLastFiveYears(): number[] {
        const currentYear = new Date().getFullYear();
        return Array.from({ length: 10 }, (_, i) => currentYear - i);
    }

    filterChanged(searchText = '') {
        const updatedSearchInput =
            searchText !== '' ? searchText : this.searchTextInput;
        this.loadArticles(
            updatedSearchInput,
            this.selectedMonth,
            this.selectedYear,
            this.selectedCategory,
        );
    }

    selectMonth(month: number | null) {
        this.selectedMonth = month;
        this.filterChanged();
    }

    selectYear(year: number | null) {
        this.selectedYear = year;
        this.filterChanged();
    }

    onSearchBoxInput(event: any) {
        this.debouncedApplyFilter(event);
    }

    ngOnInit() {
        // Getting the User ID to fetch article
        this.uid = this.route.parseUrl(
            this.route.url,
        ).root.children.primary.segments[1].path;

        // Get the css style configuration from liveSite Config
        this.webService.getConfig(this.uid).then((response: any) => {
            const {
                theme,
                footer,
                articleCards,
                contentStory,
                banner,
                buttonSectionConfig,
                gallaryConfig,
                contentCategory,
            } = response;

            // Set theme colors
            this.typeTextColor = theme.secondaryBgColor;
            this.primaryBgColor = theme.primaryBgColor;
            this.secondaryBgColor = theme.secondaryBgColor;

            // Footer configuration
            this.articlePortfolio = footer?.aboutUs?.aboutPortfolio ?? '';

            // Update press releases background color
            const pressreleases = document.getElementById('pressreleases');
            if (pressreleases) {
                pressreleases.style.backgroundColor = theme.primaryBgColor;
            }

            // Article card colors
            this.articleDateColor = articleCards.articleDateColor;
            this.articleTitleColor = articleCards.articleTitleColor;
            this.articleIngressColor = articleCards.articleIngressColor;
            this.articleAuthorColor = articleCards.articleAuthorColor;
            this.backButtonStyle = articleCards.backIcon ?? '1';
            // Content story
            if (contentStory) {
                this.futureStories = contentStory ?? {};
                this.isFutureStory = contentStory.isFutureStory ?? false;
                this.storySectionTitle = contentStory.storySectionTitle ?? '';
            }

            // Font configuration
            this.font = new Font(banner.bodyFont || theme.font);

            // Button section configuration
            const buttonConfig = buttonSectionConfig || {};
            this.separatorStyle = buttonConfig.separatorStyle ?? 1;
            this.hideDate = buttonConfig.hideDate ?? false;
            this.hideAuthor = buttonConfig.hideAuthor ?? false;
            this.hideImage = buttonConfig.hideImage ?? false;
            this.hideStartingText = buttonConfig.hideStartingText ?? false;
            this.hideReadMore = buttonConfig.hideReadMore ?? false;

            this.enableFilters = buttonConfig.enableFilters ?? false;
            this.monthTitleText = buttonConfig.monthTitleText ?? 'All Months';
            this.yearTitleText = buttonConfig.yearTitleText ?? 'All Years';
            // Gallery configuration
            if (gallaryConfig.gallaryImage) {
                this.gallaryImages = gallaryConfig.gallaryImage;
                const selectedAlbums: any =
                    gallaryConfig.selectedAlbumsOnNews ?? [];

                this.gallaryImages = this.gallaryImages.filter(
                    (mediaItem: any) => selectedAlbums.includes(mediaItem.name),
                );
            }

            this.showGalleryonNews = gallaryConfig.showOnNews ?? false;

            this.gallaryTitle = gallaryConfig.gallaryTitle ?? '';

            // News button section configuration
            this.newsHeaderTitle = buttonConfig.newsHeaderTitle ?? '';
            this.readMore = buttonConfig.readMore ?? 'Read More';
            this.readMoreColor = buttonConfig.readMoreColor ?? '#000';
            this.newsArticleBgColor = buttonConfig.newsArticleBgColor ?? '#fff';
            this.backButtonNews = buttonConfig.backButtonNews ?? '';
            this.paginationTextColor =
                buttonConfig.paginationTextColor ?? '#000';
            this.paginationNextLabel = buttonConfig.paginationNextLabel ?? '';
            this.paginationPreviousLabel =
                buttonConfig.paginationPreviousLabel ?? '';

            // Article card date configuration
            if (articleCards.articleDateLanguage) {
                this.articleDateLanguage = articleCards.articleDateLanguage;
                this._adapter.setLocale(articleCards.articleDateLanguage);
            }
            this.articleDateFormat =
                articleCards.articleDateFormat ?? 'fullDate';

            // Update latest news text color
            const latestNews = document.getElementById('latestNews');
            if (latestNews) {
                latestNews.style.color = theme.fontColor;
            }
            this.headerColor = theme.fontColor || '#000';

            // Override banner configuration
            if (banner.override) {
                this.typeTextColor = banner.bannerBgColor;
                if (pressreleases) {
                    pressreleases.style.backgroundColor =
                        response.body.bodyBgcolor;
                }
            }

            // Article card configuration
            this.articleCardConfig = {
                cardDatecolor: articleCards?.cardDatecolor ?? '#000',
                cardTitlecolor: articleCards?.cardTitlecolor ?? '#000',
                cardIngresscolor: articleCards?.cardIngresscolor ?? '#000',
                cardArticlecolor: articleCards?.cardArticlecolor ?? '#000',
                articleDateLanguage: this.articleDateLanguage,
                cardAuthorColor: articleCards?.cardAuthorColor ?? '#000',
                hideAuthor: this.hideAuthor,
                viewMore: buttonConfig?.viewMore ?? 'View More',
                viewMoreColor: buttonConfig?.viewMoreColor ?? '#fff',
                typeTextColor: theme.primaryBgColor,
                articleDateFormat: this.articleDateFormat,
            };

            if (contentCategory) {
                this.contentCategory = contentCategory;
            }
        });
        this.loadArticles();
        const path = '/pressroom/' + this.uid + '/news';

        setTimeout(() => {
            this.analyticsUrl = `${environment.url}api/v1/events${path}`;
        }, 10000);
    }
    getCategoryName(categoryId: any, contentCategory: any): string {
        if (!contentCategory) {
            return '';
        }
        const category = contentCategory.find(
            (cat: any) => cat.categoryId === categoryId,
        );
        return category ? category.categoryName.trim() : '';
    }
    fetchByCategory(event: any) {
        this.isCategoryFilterApplied = true;
        this.currentPage = 1;
        this.selectedCategoryName = this.getCategoryName(
            this.selectedCategory[0],
            this.contentCategory.categories,
        );
        this.loadArticles(
            this.searchTextInput,
            this.selectedMonth,
            this.selectedYear,
            this.selectedCategory,
        );
        this.isCategoryFilterApplied = false;
    }

    panelClosed(): void {
        this.panelOpenState = false;
    }

    loadArticles(searchText = '', month = null, year = null, categories = []) {
        this.isPrintArticleLoading = true;
        const articleQueryParams: ArticleQueryParams = {
            page: this.currentPage,
            itemsPerPage: this.itemsPerPage,
            searchText,
            month,
            year,
            isVisible: true,
            filterCategories: categories[0] ? categories : [],
        };

        this.newsService
            .getArticles(articleQueryParams)
            .pipe(
                catchError((error) => {
                    this.printArticle = [];
                    return of(null);
                }),
                finalize(() => {
                    this.isPrintArticleLoading = false;
                }),
            )
            .subscribe((val) => {
                if (val !== null) {
                    window.scrollTo({
                        top: 0,
                        left: 100,
                        behavior: 'smooth',
                    });
                    this.printArticle = val.response;
                    this.totalItems = val.total;
                    this.contentCategory.categories = val.categories;
                }
            });
    }

    onPageChange(page: number): void {
        this.currentPage = page;
        this.isPrintArticleLoading = true;

        this.loadArticles(
            this.searchTextInput,
            this.selectedMonth,
            this.selectedYear,
            this.selectedCategory,
        );
    }

    returnBack() {
        this.location.back();
    }

    openArticle(item: any) {
        const path = '/pressroom/' + item.pressroomId + '/article/' + item._id;
        this.route.navigateByUrl(path);
    }

    htmlToPlaintext(text: any) {
        try {
            const doc = new DOMParser().parseFromString(text, 'text/html');
            text = doc.body.textContent || '';
        } catch (ex) {}
        text = text.replace(/\s\s+/g, ' ');
        return text;
    }
}
