import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-article-card-two',
    templateUrl: './article-card-two.component.html',
    styleUrl: './article-card-two.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleCardTwoComponent {
    @Input() article: any;
    @Input() config: any;
    @Input() ngClassStyle: any;
    @Input() noImage: any;
    @Input() hideSocialIcons: any;
    @Input() isCompact: any;
    @Input() contentCategory: any;

    constructor(private router: Router) {}

    htmlToPlaintext(text: any) {
        try {
            const doc = new DOMParser().parseFromString(text, 'text/html');
            text = doc.body.textContent || '';
        } catch (ex) {}
        text = text.replace(/\s\s+/g, ' ');
        return text.trim();
    }

    openArticle(item: any) {
        if (!this.article.isDummy)
            this.router.navigateByUrl(
                '/pressroom/' + item.pressroomId + '/article/' + item._id,
            );
    }

    shareFB(data: any) {
        const link =
            'http://www.facebook.com/sharer.php?u=' +
            window.location.protocol +
            '//' +
            window.location.hostname +
            '/pressroom/' +
            data.pressroomId +
            '/article/' +
            data._id;

        return link;
    }

    shareTwitter(data: any) {
        const link =
            'https://x.com/intent/tweet?post=' +
            window.location.protocol +
            '//' +
            window.location.hostname +
            '/pressroom/' +
            data.pressroomId +
            '/article/' +
            data._id;

        return link;
    }
    shareLinkedIn(data: any) {
        const link =
            'https://www.linkedin.com/shareArticle?mini=true&url=' +
            window.location.protocol +
            '//' +
            window.location.hostname +
            '/pressroom/' +
            data.pressroomId +
            '/article/' +
            data._id;
        return link;
    }
}
