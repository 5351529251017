import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    AfterViewInit,
} from '@angular/core';
import { WebPageConfigService } from 'src/app/dashboard/website-config/website-config.service';
import { ActivatedRoute, Router } from '@angular/router';
// import { GallaryDialogComponent } from './album-images/gallary-dialog/gallary-dialog.component';
import { MatDialog } from '@angular/material/dialog';
// import { AlbumImagesComponent } from './album-images/album-images.component';
import { Location } from '@angular/common';
import { Font } from 'ngx-font-picker';
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { NewsService } from '../news/news.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrl: './gallery.component.scss',
    animations: [
        trigger('fadeInOut', [
            state('void', style({ opacity: 0 })),
            transition('void <=> *', [animate(500)]),
        ]),
    ],
})
export class GalleryComponent implements OnInit {
    printArticle: any = [];
    typeTextColor: any;
    uid!: any;
    isActive!: any;
    hideBanner!: any;
    backButtonGallary!: any;
    gallaryTitle = '';
    imageItem: any;
    font: Font | any;
    bannerHeight: any | '30vh';
    bannerHeaderTextSize: any | '50px';
    bannerSubHeaderTextSize: any | '30px';
    bannerTextSlides: any = [];
    galleryConfig: any;
    backButtonStyle = '1';

    primaryBgColor: any;
    articlePortfolio: any;
    analyticsUrl = '';

    sliderConfig = {
        sliderImageWidth: '260px',
        sliderImageHeight: 'auto',
        sliderSlideImage: '1',
        sliderAutoSlide: true,
        sliderAnimationSpeed: 1,
        sliderInfinite: true,
        sliderImagePopup: false,
        manageImageRatio: false,
        HideShowSliderArrow: true,
        videoAutoPlay: true,
        showVideoControls: true,
        direction: 'ltr',
    };

    constructor(
        private webService: WebPageConfigService,
        public dialog: MatDialog,
        private router: Router,
        private location: Location,
    ) {}

    ngOnInit() {
        this.uid = this.router.parseUrl(
            this.router.url,
        ).root.children.primary.segments[1].path;

        this.webService.getConfig(this.uid).then(async (resp: any) => {
            const {
                banner,
                theme,
                gallaryConfig,
                footer,
                images,
                buttonSectionConfig,
                body,
                articleCards,
            } = resp;

            // Font configuration
            this.font = new Font(banner.bodyFont || theme.font);
            this.typeTextColor = theme.secondaryBgColor;

            // Gallery configuration
            this.gallaryTitle = gallaryConfig.gallaryTitle ?? '';

            // Footer configuration
            this.articlePortfolio = footer?.aboutUs?.aboutPortfolio ?? '';
            this.backButtonStyle = articleCards.backIcon ?? '1';
            // Primary background color
            this.primaryBgColor = theme.primaryBgColor;
            this.hideBanner = gallaryConfig.hideBanner ?? false;

            // Update live site body styles
            const siteBody = document.getElementById('site-body');
            if (siteBody) {
                siteBody.style.backgroundColor = theme.primaryBgColor;
                siteBody.style.color = theme.fontColor;
            }

            // Banner configuration
            if (!this.hideBanner) {
                const bannerElement = document.getElementById('banner');
                if (bannerElement) {
                    bannerElement.style.color = theme.fontColor;
                    bannerElement.style.backgroundImage = `url(${images.bannerImage})`;
                    bannerElement.style.height = banner.bannerHeight;
                    bannerElement.style.backgroundColor = theme.primaryBgColor;
                    bannerElement.title = images.bannerImageTooltipAltText;
                    this.bannerHeight = banner.bannerHeight;
                    this.bannerTextSlides = banner.bannerSliderText ?? [];
                    this.bannerHeaderTextSize = banner.bannerHeaderTextSize;
                    this.bannerSubHeaderTextSize =
                        banner.bannerSubHeaderTextSize;

                    if (this.bannerTextSlides.length > 0) {
                        setTimeout(() => {
                            $('#light-slider').lightSlider({
                                controls: true,
                                auto: true,
                                loop:
                                    this.bannerTextSlides.length > 1
                                        ? true
                                        : false,
                                item: 1,
                                pager: false,
                                speed: 1000,
                                pause: 3500,
                            });
                        });
                    }

                    bannerElement.setAttribute(
                        'alt',
                        images.bannerImageTooltipAltText,
                    );
                }
            }

            // Back button gallery
            this.backButtonGallary =
                buttonSectionConfig?.backButtonGallary ?? '';

            // Gallery images processing
            if (gallaryConfig.gallaryImage) {
                this.galleryConfig = gallaryConfig.gallaryImage;
            }

            // Banner override configuration
            if (banner.override) {
                if (!this.hideBanner) {
                    const bannerElement = document.getElementById('banner');
                    const bannerOverlay =
                        document.getElementById('bannerOverlay');

                    if (bannerElement) {
                        bannerElement.style.backgroundColor =
                            banner.bannerBgColor;
                        bannerElement.style.color = banner.bannerTextColor;
                    }

                    if (bannerOverlay && this.bannerTextSlides.length > 0) {
                        bannerOverlay.style.backgroundColor =
                            banner.bannerBgColor;
                    }
                }

                this.typeTextColor = banner.bannerBgColor;

                if (siteBody) {
                    siteBody.style.backgroundColor = body.bodyBgcolor;
                }
            } else {
                const bannerOverlay = document.getElementById('bannerOverlay');
                if (bannerOverlay && this.bannerTextSlides.length > 0) {
                    bannerOverlay.style.backgroundColor =
                        theme.secondaryBgColor;
                }
            }
        });
        const path = '/pressroom/' + this.uid + '/media';
        setTimeout(() => {
            this.analyticsUrl = `${environment.url}api/v1/events${path}`;
        }, 5000);
    }

    returnBack() {
        this.location.back();
    }
}
