<div id="articleContainer">
    <div class="article" *ngIf="data">
        <div *ngFor="let data of data">
            <mat-card class="article-card">
                <div class="article-header" [ngStyle]="font?.getStyles()">
                    <div
                        *ngIf="!isNavLink.includes(data._id) && !hideDate"
                        class="article-date"
                        [ngStyle]="{ color: articleDateColor }"
                    >
                        <div>
                            <span>
                                {{
                                    data.date
                                        | date
                                            : articleDateFormat
                                            : ''
                                            : articleDateLanguage
                                }}
                            </span>
                            <span
                                style="margin-left: 5px; font-size: small"
                                *ngIf="contentCategory?.showOnArticlePage"
                            >
                                <app-category-chip
                                    [categoryId]="data.categoryId"
                                    [categories]="
                                        contentCategory.categories ?? []
                                    "
                                    [bgColor]="
                                        contentCategory?.categoryBackground
                                    "
                                    [textColor]="
                                        contentCategory?.categoryTextColor
                                    "
                                ></app-category-chip>
                            </span>
                        </div>
                        <div class="footer-right">
                            <span>
                                <a href="{{ link }}" target="_blank">
                                    <mat-icon
                                        class="icon-item"
                                        svgIcon="{{ shareIconSet }}_facebook"
                                        (click)="shareFB()"
                                    ></mat-icon>
                                </a>
                            </span>
                            <span>
                                <a href="{{ link2 }}" target="_blank">
                                    <mat-icon
                                        class="icon-item"
                                        svgIcon="{{ shareIconSet }}_x"
                                        (click)="shareTwitter()"
                                    ></mat-icon>
                                </a>
                                <script
                                    async
                                    src="https://platform.x.com/widgets.js"
                                    charset="utf-8"
                                ></script>
                            </span>
                            <span>
                                <a href="{{ link1 }}" target="_blank">
                                    <mat-icon
                                        class="icon-item"
                                        svgIcon="{{ shareIconSet }}_linkedin"
                                        (click)="shareLinkedIn()"
                                    ></mat-icon>
                                </a>
                            </span>
                        </div>
                    </div>

                    <mat-divider
                        *ngIf="!isNavLink.includes(data._id)"
                    ></mat-divider>
                    <div
                        class="article-title"
                        [ngStyle]="{ color: articleTitleColor }"
                    >
                        {{ data.title }}
                    </div>
                    <br />
                    <div
                        *ngIf="!isNavLink.includes(data._id) && !hideAuthor"
                        class="article-author"
                        [ngStyle]="{ color: articleAuthorColor }"
                    >
                        {{ data.author | slice: 0 : 25 }}
                    </div>
                </div>

                <div class="article-body">
                    <div
                        *ngIf="
                            data.image_URL &&
                            !isBodyImgSame &&
                            data.imageDisplay &&
                            !hideImage
                        "
                    >
                        <img
                            class="article-image lazy-loading"
                            [appLazyLoad]="data.image_URL"
                            loading="lazy"
                            alt="Content image"
                        />
                    </div>
                    <div class="article-content innerHtml">
                        <div
                            *ngIf="data.ingress"
                            [ngStyle]="{ color: articleIngressColor }"
                            class="content-text"
                        >
                            {{ data.ingress }}
                        </div>
                        <div
                            class="content-text"
                            [innerHTML]="
                                cleanHTML(data.main_Body) | sanitizeHtml: 'html'
                            "
                        ></div>
                    </div>
                </div>
                <img
                    *ngIf="analyticsUrl"
                    [appLazyLoad]="analyticsUrl"
                    loading="lazy"
                    class="analytics-image"
                />
                <div class="article-footer" [ngStyle]="font?.getStyles()">
                    <div class="footer-left">
                        <mat-icon
                            class="icon-item"
                            svgIcon="{{ backIcon }}_return"
                            (click)="returnBack()"
                        ></mat-icon>
                    </div>
                    <div class="footer-right">
                        <span>
                            <a href="{{ link }}" target="_blank">
                                <mat-icon
                                    class="icon-item"
                                    svgIcon="{{ shareIconSet }}_facebook"
                                    (click)="shareFB()"
                                ></mat-icon>
                            </a>
                        </span>
                        <span>
                            <a href="{{ link2 }}" target="_blank">
                                <mat-icon
                                    class="icon-item"
                                    svgIcon="{{ shareIconSet }}_x"
                                    (click)="shareTwitter()"
                                ></mat-icon>
                            </a>
                            <script
                                async
                                src="https://platform.x.com/widgets.js"
                                charset="utf-8"
                            ></script>
                        </span>
                        <span>
                            <a href="{{ link1 }}" target="_blank">
                                <mat-icon
                                    class="icon-item"
                                    svgIcon="{{ shareIconSet }}_linkedin"
                                    (click)="shareLinkedIn()"
                                ></mat-icon>
                            </a>
                        </span>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
    <div *ngIf="isArticleFound === false">
        <app-page-not-found></app-page-not-found>
    </div>
    <div class="article" *ngIf="!data && isArticleFound">
        <ngx-skeleton-loader
            [theme]="{
                width: '100%',
                'border-radius': '1.3rem',
                height: '80vh',
                'margin-bottom': '20px',
                'background-color': 'whitesmoke'
            }"
        ></ngx-skeleton-loader>
    </div>

    <div *ngIf="showRecentArticles" style="padding-bottom: 2em; display: flex">
        <div class="container">
            <div class="body-heading" [ngStyle]="font?.getStyles()">
                <span
                    class="separator"
                    *ngIf="separatorStyle === 1"
                    [ngStyle]="{ 'background-color': typeTextColor }"
                ></span>
                <h1>{{ recentArticlesSectionTitle || 'Recent Releases' }}</h1>
                <span
                    class="separator-2"
                    *ngIf="separatorStyle === 2"
                    [ngStyle]="{ 'background-color': typeTextColor }"
                ></span>
            </div>
            <div class="col-lg-12">
                <div *ngFor="let item of printArticle" class="itemList">
                    <app-article-card-two
                        style="width: 90%; display: inherit"
                        [article]="item"
                        [config]="articleCardConfig"
                        ngClassStyle="width-100"
                        [noImage]="false"
                        hideSocialIcons="true"
                        isCompact="true"
                        [contentCategory]="contentCategory"
                    ></app-article-card-two>
                </div>
                <div
                    style="width: 93%; margin-left: 54px"
                    *ngIf="isPrintArticleLoading"
                >
                    <ngx-skeleton-loader
                        *ngFor="let n of [].constructor(5); let i = index"
                        [theme]="{
                            width: '100%',
                            'border-radius': '0.5rem',
                            height: '150px',
                            'margin-bottom': '20px',
                            'background-color': 'whitesmoke'
                        }"
                    ></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div>
    <div
        class="container"
        *ngIf="articlePortfolio?.showOnArticle"
        style="padding-bottom: 2em"
    >
        <div class="body-heading" [ngStyle]="font?.getStyles()">
            <span
                class="separator"
                *ngIf="
                    articlePortfolio?.portfolioSectionTitle &&
                    separatorStyle === 1
                "
                [ngStyle]="{ 'background-color': typeTextColor }"
            ></span>
            <h1 *ngIf="articlePortfolio.portfolioSectionTitle !== ''">
                {{ articlePortfolio.portfolioSectionTitle }}
            </h1>
            <span
                class="separator-2"
                *ngIf="
                    articlePortfolio?.portfolioSectionTitle &&
                    separatorStyle === 2
                "
                [ngStyle]="{ 'background-color': typeTextColor }"
            ></span>
        </div>
        <app-article-portfolio
            [articlePortfolio]="articlePortfolio"
            [typeTextColor]="typeTextColor"
            [primaryBgColor]="primaryBgColor"
            [bodyFont]="font"
            style="color: initial"
        ></app-article-portfolio>
    </div>
</div>
