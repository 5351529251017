import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { PageNotFoundComponent } from './layout/page-not-found/page-not-found.component';
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';
import { LoginComponent } from './login/login.component';
import { SiteComponent } from './site/site.component';
import { HomeComponent } from './site/home/home.component';
import { NewsComponent } from './site/news/news.component';
import { GalleryComponent } from './site/gallery/gallery.component';
import { ArticleComponent } from './site/article/article.component';
import { FeedComponent } from './site/feed/feed.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full',
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'pressroom/:id',
        component: SiteComponent,
        children: [
            {
                path: '',
                redirectTo: '/pressroom/:id/home',
                pathMatch: 'full',
            },
            {
                path: 'home',
                component: HomeComponent,
            },
            {
                path: 'news',
                component: NewsComponent,
            },
            {
                path: 'media',
                component: GalleryComponent,
            },
            {
                path: 'article/:id',
                component: ArticleComponent,
                data: {
                    title: 'Innovative Newsroom Solutions',
                    descrption:
                        'Empowering Your Media Presence with Cutting-Edge Design and Technology.',
                    ogTitle: 'Innovative Newsroom Solutions',
                },
            },
            {
                path: '**',
                component: PageNotFoundComponent,
            },
        ],
    },
    {
        path: 'pressroom/:id/feed',
        component: FeedComponent,
    },
    {
        path: '',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        children: [
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full',
            },
            {
                path: 'dashboard',
                loadChildren: () =>
                    import('./dashboard/dashboard.module').then(
                        (m) => m.DashboardModule,
                    ),
            },
            {
                path: 'settings',
                loadChildren: () =>
                    import('./settings/settings.module').then(
                        (m) => m.SettingsModule,
                    ),
            },
            {
                path: '**',
                component: PageNotFoundComponent,
            },
        ],
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            scrollOffset: [0, 64],
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
