import { Component, AfterViewInit } from '@angular/core';
import { WebPageConfigService } from 'src/app/dashboard/website-config/website-config.service';
import { Router } from '@angular/router';
import { NewsService, ArticleQueryParams } from '../news/news.service';
import { ArticleService } from '../article/article.service';
// import { ShareToSocialMediaService } from './../../share-to-social-media.service';
import nodeHtmlParser from 'node-html-parser';
import { Font } from 'ngx-font-picker';
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss',
    animations: [
        trigger('fadeInOut', [
            state('void', style({ opacity: 0 })),
            transition('void <=> *', [animate(500)]),
        ]),
    ],
})
export class HomeComponent implements AfterViewInit {
    p = 1;
    data!: any;
    mainBody!: any;
    printArticle: any = [];
    isPrintArticleLoading = true;

    currentURL!: any;
    routerUrl!: any;
    link!: any;
    link1!: any;
    link2!: any;
    typeTextColor!: any;
    cardAuthorColor!: any;
    cardDatecolor!: any;
    cardIngresscolor!: any;
    cardTitlecolor!: any;
    cardArticlecolor!: any;
    uid!: any;
    isActive!: any;
    cardStyle!: any;
    cardImageStyle!: any;
    articleDateFormat!: any;
    viewMore!: any;
    viewMoreColor!: any;
    showMore!: any;
    showMoreColor!: any;
    isPaginationOnHome!: any;
    hideBanner = false;
    newSubscribe: any;
    cardImageBackgroundColor = '#fff';
    contentTitle: String = '';
    backgroundStyleDynamic: any;
    imageObject: any = [];
    sliderWidth = '100%';
    hideAuthor: boolean = false;

    sliderConfig: any;
    futureStories: any;
    isFutureStory = false;
    showSlider = false;
    storySectionTitle: any;
    separatorStyle: any;
    articleDateLanguage = 'en';
    defaultCardImage = './assets/images/images/press-release.jpg';
    paginationTextColor: any;
    paginationNextLabel: any;
    paginationPreviousLabel: any;
    directionLinks = true;
    responsive: boolean = true;
    font: Font | any;
    bannerHeight: any | '30vh';
    bannerHeaderTextSize: any | '50px';
    bannerSubHeaderTextSize: any | '30px';
    bannerTextSlides: any = [];
    primaryBgColor: any;
    articlePortfolio: any;
    analyticsUrl = '';

    articleCardConfig: any;
    maxSize = 6;
    itemsPerPage = 6;
    currentPage = 1;
    totalItems = 0;
    isLoading = false;
    contentCategory: any;

    constructor(
        private webService: WebPageConfigService,
        public articleService: ArticleService,
        private router: Router,
        private newsService: NewsService,
    ) {}

    ngAfterViewInit() {
        this.sliderConfig = {
            sliderImageWidth: '',
            sliderImageHeight: '',
            sliderSlideImage: '',
            sliderAutoSlide: null,
            sliderAnimationSpeed: null,
            sliderInfinite: null,
            sliderImagePopup: null,
            manageImageRatio: null,
            HideShowSliderArrow: null,
            videoAutoPlay: null,
            showVideoControls: null,
            direction: '',
        };

        // Getting the User ID to fetch article
        this.routerUrl = window.location.href;

        this.uid = this.router.parseUrl(
            this.router.url,
        ).root.children.primary.segments[1].path;

        this.webService.getConfig(this.uid).then((resp: any) => {
            const {
                theme,
                banner,
                articleCards,
                buttonSectionConfig,
                body,
                contentStory,
                footer,
                images,
                contentCategory,
            } = resp;

            // Theme configuration
            this.typeTextColor = theme.secondaryBgColor;
            this.primaryBgColor = theme.primaryBgColor;

            // Font configuration
            this.font = new Font(banner.bodyFont ?? theme.font);

            if (contentCategory) {
                this.contentCategory = contentCategory;
            }

            // Article cards configuration
            this.articleDateFormat =
                articleCards?.articleDateFormat ?? 'fullDate';
            this.cardDatecolor = articleCards?.cardDatecolor ?? '#000';
            this.cardTitlecolor = articleCards?.cardTitlecolor ?? '#000';
            this.cardIngresscolor = articleCards?.cardIngresscolor ?? '#000';
            this.cardArticlecolor = articleCards?.cardArticlecolor ?? '#000';
            this.articleDateLanguage =
                articleCards?.articleDateLanguage ?? 'en';
            this.cardImageStyle = articleCards?.cardImageStyle ?? 'cover';
            this.cardImageBackgroundColor =
                articleCards?.cardImageBackgroundColor ?? '#000';
            this.cardAuthorColor = articleCards?.cardAuthorColor ?? '#000';
            this.defaultCardImage = articleCards?.defaultCardImage ?? '';

            // Button section configuration
            this.hideAuthor = buttonSectionConfig?.hideAuthor ?? false;
            this.viewMore = buttonSectionConfig?.viewMore ?? 'View More';
            this.showMore = buttonSectionConfig?.showMore ?? 'Show More';
            this.viewMoreColor = buttonSectionConfig?.viewMoreColor ?? '#fff';
            this.showMoreColor = buttonSectionConfig?.showMoreColor ?? '#fff';
            this.isPaginationOnHome =
                buttonSectionConfig?.isPaginationOnHome ?? false;
            this.paginationTextColor =
                buttonSectionConfig?.paginationTextColor ?? '#000';
            this.paginationNextLabel =
                buttonSectionConfig?.paginationNextLabel ?? '';
            this.paginationPreviousLabel =
                buttonSectionConfig?.paginationPreviousLabel ?? '';

            this.separatorStyle = buttonSectionConfig.separatorStyle || 1;

            // Content title
            if (this.valid(body?.bodyContentTitle)) {
                this.contentTitle = body.bodyContentTitle;
            }

            // Live site body styles
            const siteBody = document.getElementById('site-body');
            if (siteBody) {
                siteBody.style.backgroundColor = theme.primaryBgColor;
                siteBody.style.color = theme.fontColor;
            }

            // Banner configuration
            this.hideBanner = banner.hide ?? false;
            if (!this.hideBanner) {
                const bannerElement = document.getElementById('banner');
                if (bannerElement) {
                    bannerElement.style.color = theme.fontColor;
                    bannerElement.style.backgroundImage = `url(${images.bannerImage})`;
                    bannerElement.style.height = banner.bannerHeight;
                    bannerElement.style.backgroundColor = theme.primaryBgColor;
                    bannerElement.title = images.bannerImageTooltipAltText;
                    this.bannerHeight = banner.bannerHeight ?? '';
                    this.bannerTextSlides = banner.bannerSliderText ?? [];
                    this.bannerHeaderTextSize =
                        banner.bannerHeaderTextSize ?? '';
                    this.bannerSubHeaderTextSize =
                        banner.bannerSubHeaderTextSize ?? '';
                    if (this.bannerTextSlides.length > 0) {
                        setTimeout(() => {
                            $('#light-slider').lightSlider({
                                controls: true,
                                auto: true,
                                loop:
                                    this.bannerTextSlides.length > 1
                                        ? true
                                        : false,
                                item: 1,
                                pager: false,
                                speed: 1000,
                                pause: 3500,
                            });
                        });
                    }
                }
                document
                    .getElementById('banner')
                    ?.setAttribute('alt', images.bannerImageTooltipAltText);
            }

            // Slider configuration
            this.showSlider = banner.isSlider ?? false;
            this.sliderWidth = banner.sliderWidth ?? '';
            this.imageObject = banner.sliderImages ?? [];
            this.sliderConfig = {
                sliderImageWidth: banner.sliderImageWidth ?? 0,
                sliderImageHeight: banner.sliderImageHeight ?? 0,
                sliderSlideImage: banner.sliderSlideImage ?? '',
                sliderAutoSlide: banner.sliderAutoSlide ?? false,
                sliderAnimationSpeed: Number(banner.sliderAnimationSpeed) ?? 0,
                sliderInfinite: banner.sliderInfinite ?? false,
                sliderImagePopup: banner.sliderImagePopup ?? false,
                manageImageRatio: banner.manageImageRatio ?? false,
                HideShowSliderArrow: banner.HideShowSliderArrow ?? false,
                videoAutoPlay: banner.videoAutoPlay ?? false,
                showVideoControls: banner.showVideoControls ?? false,
                direction: banner.direction ?? 'horizontal',
            };

            // Content story configuration
            if (contentStory) {
                this.futureStories = contentStory ?? {};
                this.isFutureStory = contentStory.isFutureStory ?? false;
                this.storySectionTitle = contentStory.storySectionTitle ?? '';
            }

            // Footer configuration
            this.articlePortfolio = footer?.aboutUs?.aboutPortfolio ?? '';

            // Background style dynamic configuration
            const backgroundStyleDynamic = {
                background:
                    this.cardImageStyle === 'gradient'
                        ? `linear-gradient('${this.typeTextColor ?? theme.secondaryBgColor}', black)`
                        : '',
                'background-color':
                    this.cardImageStyle === 'color'
                        ? this.cardImageBackgroundColor
                        : '',
            };

            // Banner override configuration
            if (banner.override) {
                const bannerElement = document.getElementById('banner');
                const bannerOverlay = document.getElementById('bannerOverlay');
                if (bannerElement) {
                    bannerElement.style.backgroundColor =
                        banner.bannerBgColor ?? theme.primaryBgColor;
                    bannerElement.style.color =
                        banner.bannerTextColor ?? theme.fontColor;
                }
                if (bannerOverlay && this.bannerTextSlides.length > 0) {
                    bannerOverlay.style.backgroundColor =
                        banner.bannerBgColor ?? theme.primaryBgColor;
                }
                this.typeTextColor =
                    banner.bannerBgColor ?? theme.primaryBgColor;
                if (siteBody) {
                    siteBody.style.backgroundColor =
                        body?.bodyBgcolor ?? theme.primaryBgColor;
                }
            } else {
                const bannerOverlay = document.getElementById('bannerOverlay');
                if (bannerOverlay && this.bannerTextSlides.length > 0) {
                    bannerOverlay.style.backgroundColor =
                        theme.secondaryBgColor;
                }
            }

            // Card style and article card configuration
            this.cardStyle = resp.cardStyle ?? '';

            this.articleCardConfig = {
                cardDatecolor: this.cardDatecolor,
                cardTitlecolor: this.cardTitlecolor,
                cardIngresscolor: this.cardIngresscolor,
                cardArticlecolor: this.cardArticlecolor,
                articleDateLanguage: this.articleDateLanguage,
                cardImageStyle: this.cardImageStyle,
                cardImageBackgroundColor: this.cardImageBackgroundColor,
                cardAuthorColor: this.cardAuthorColor,
                defaultCardImage: this.defaultCardImage,
                hideAuthor: this.hideAuthor,
                viewMore: this.viewMore,
                viewMoreColor: this.viewMoreColor,
                backgroundStyleDynamic,
                typeTextColor: theme.primaryBgColor,
                articleDateFormat: this.articleDateFormat,
            };
        });
        this.loadArticles();

        const path = '/pressroom/' + this.uid + '/home';
        setTimeout(() => {
            this.analyticsUrl = `${environment.url}api/v1/events${path}`;
        }, 5000);
    }
    loadArticles() {
        // Get the article Content, sort and  display it
        const articleQueryParams: ArticleQueryParams = {
            page: this.currentPage,
            itemsPerPage: this.itemsPerPage,
            isVisible: true,
        };
        this.newsService
            .getArticles(articleQueryParams)
            .subscribe((val: any) => {
                this.printArticle = val.response.map((article: any) => {
                    let node_root: any = nodeHtmlParser(article.main_Body);
                    if (node_root.querySelector('img')) {
                        return {
                            ...article,
                            isBodyImgSame:
                                article.image_URL ===
                                node_root.querySelector('img').attributes.src,
                        };
                    }

                    return {
                        ...article,
                        isBodyImgSame: false,
                    };
                });

                this.totalItems = val.total;
                this.isPrintArticleLoading = false;
            });
    }
    onPageChange(page: number): void {
        this.currentPage = page;
        this.isPrintArticleLoading = true;
        this.loadArticles();
    }

    // Redirecting to News Page
    openNews() {
        const path = '/pressroom/' + this.uid + '/news';
        this.router.navigateByUrl(path);
    }

    // Converting HTML main Body to Plain Text to remove whitespaces
    htmlToPlaintext(text: any) {
        try {
            const doc = new DOMParser().parseFromString(text, 'text/html');
            text = doc.body.textContent || '';
        } catch (ex) {}
        text = text.replace(/\s\s+/g, ' ');
        return text.trim();
    }

    valid(str: any) {
        if (str === '' || str === null || str === undefined) return false;
        else return true;
    }
}
